import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {useSelector} from 'react-redux';
import dayjs from 'dayjs';

import {Divider, Group, Loader, Menu, ScrollArea, Stack} from '@mantine/core';

// Global components
import Text from 'components/core/Text';

// Fontawesome
import FaDisplay from 'components/others/FaDisplay';
import {
	faChevronDown, faCircleSmall, faDoorOpen,
	faGear, faLoader, faShop,
	faSignOut, faSync, faTimer,
	faWifiExclamation,
} from '@fortawesome/pro-solid-svg-icons';

// Local styles
import {useStyles} from './styles';
import {getIndonesianTimezoneLabel} from 'utils';
import AuthUtils from 'utils/auth';
import ChangeOutletModal from '../ChangeOutletModal';

const NavUser = () => {
	const navigate = useNavigate();
	const {classes, theme, cx} = useStyles();
	const {t} = useTranslation('translation', {keyPrefix: 'global.header.navUser'});

	const allowPos = useSelector(({reducerElectron}) => reducerElectron.allowPos);
	const isOnline = useSelector(({reducerOrder}) => reducerOrder?.connectedToServer) || false;
	const backgroundSync = useSelector(({reducerBackgroundSync}) => reducerBackgroundSync) || {};
	const {selectedLocation, username} = useSelector(({reducerUser}) => reducerUser) || {};

	const isSyncing = Object.values(backgroundSync).some(value => value === true) || false;
	const [currentTime, setCurrentTime] = useState(dayjs());
	const [changeOutletModalOpen, setChangeOutletModalOpen] = useState(false);

	const serviceLoading = isOnline === null;

	const menuItems = [
		...(allowPos ? [{icon: faTimer, label: t('menu.manageShift'), onClick: () => navigate('/shift')}] : []),
		{icon: faDoorOpen, label: t('menu.manageOnOff'), onClick: () => navigate('/outlet-status')},
		...(allowPos ? [{icon: faGear, label: t('menu.settings'), onClick: () => navigate('/settings')}] : []),
		// {icon: faShop, label: t('menu.changeOutlet'), onClick: () => setChangeOutletModalOpen(true)},
	];

	const NavUserArea = () => (
		<Group
			noWrap
			className={classes.container}
			spacing={4}>
			<FaDisplay
				className={
					isOnline
						? isSyncing
							? classes.iconWrapperSync
							: classes.iconWrapperDefault
						: classes.iconWrapperDanger
				}
				containerSize={32}
				fontSize={14}
				faProps={{spin: isSyncing && isOnline}}
				faIcon={
					isOnline
						? isSyncing
							? faSync
							: faShop
						: faWifiExclamation
				}
			/>
			<Stack
				className={classes.rightSection}
				spacing={0}>
				{/* Top Section */}
				<Group
					position='apart'
					noWrap
					spacing={4}>
					<Text
						className={classes.text}
						lineClamp={1}
						typography='title-bold-14'>{selectedLocation?.label}</Text>
					<FaDisplay
						containerSize={14}
						fontSize={12}
						faIcon={faChevronDown}
					/>
				</Group>

				{/* Bottom Section */}
				<Group
					noWrap
					spacing={4}>
					{serviceLoading
						? (
							<Loader
								size={12}
								color={theme.colors.light.text.secondary}
							/>
						)
						: (
							<Text
								className={cx({
									[classes.statusTextOnline]: isOnline === true,
									[classes.statusTextOffline]: isOnline === false,
								})}
								typography='caption-bold-12'>
								{t(isOnline ? 'status.online' : 'status.offline')}
							</Text>
						)}
					<Text
						lineClamp={1}
						className={classes.text}
						typography='caption-regular-12'> · {username}</Text>
				</Group>

			</Stack>
		</Group>
	);

	const SyncInfo = () => {
		const tempStatus = [
			{isRunning: backgroundSync?.autoSyncRunning, label: t('runningSync.autoSync')},
			{isRunning: backgroundSync?.deepSyncRunning, label: t('runningSync.deepSync')},
			{isRunning: backgroundSync?.quickSyncRunning, label: t('runningSync.quickSync')},
			{isRunning: backgroundSync?.manualSyncRunning, label: t('runningSync.manualSync')},
		];

		return (
			<React.Fragment>
				{
					tempStatus
						?.filter(syncStatus => syncStatus.isRunning)
						?.map(runningSync => (
							<Group
								key={runningSync.label}
								noWrap
								className={classes.syncIndicatorWrapper}
								spacing={4}>
								<FaDisplay
									faIcon={faSync}
									fontSize={12}
									containerSize={14}
									faProps={{spin: true}}
								/>
								<Text typography='caption-medium-12'>
									{runningSync.label}
								</Text>
							</Group>
						))
				}
			</React.Fragment>
		);
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentTime(dayjs());
		}, 1000);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	return (
		<>
			<ChangeOutletModal
				changeOutletModalOpen={changeOutletModalOpen}
				setChangeOutletModalOpen={setChangeOutletModalOpen}
			/>
			<Menu
				transition="pop-top-right"
				placement="end"
				gutter={6}
				classNames={{
					body: classes.menuBody,
					itemHovered: classes.itemHovered,
				}}
				control={(
					<button className={classes.cleanBtn}>
						<NavUserArea />
					</button>
				)}
			>
				<Stack spacing={12}>

					{/* Top Section */}
					<Stack
						pt={16}
						spacing={12}>

						{/* User Info */}
						<Stack
							px={16}
							spacing={0}>
							<Text
								lineClamp={2}
								typography='title-bold-14'>
								{selectedLocation?.label}
							</Text>
							<Group spacing={4}>
								<Text
									color={theme.colors.light.text.secondary}
									typography='caption-regular-12'>{username}</Text>
								<Text
									color={theme.colors.light.text.secondary}
									typography='caption-regular-12'>
									{`· ${currentTime.format?.('hh:mm:ss')} ${getIndonesianTimezoneLabel()}`}
								</Text>
							</Group>
						</Stack>

						{/* Service Info */}
						<ScrollArea
							type='never'
							scrollbarSize={5}
						>
							<Group
								px={16}
								noWrap
								spacing={4}>

								{/* Service Info */}
								<Group
									noWrap
									className={cx(
										classes.serviceIndicatorWrapper,
										{
											[classes.serviceOnline]: isOnline === true,
											[classes.serviceOffline]: isOnline === false,
										},
									)}
									spacing={4}>
									<FaDisplay
										faIcon={serviceLoading ? faLoader : faCircleSmall}
										fontSize={12}
										containerSize={14}
										faProps={{spin: serviceLoading ? true : false}}
									/>
									<Text typography='caption-medium-12'>
										{t(serviceLoading ? 'menu.loading' : 'menu.frestoStatus', {
											status: isOnline ? t('menu.online') : t('menu.offline'),
										})}
									</Text>
								</Group>

								{/* Sync Info */}
								<SyncInfo />

							</Group>
						</ScrollArea>
					</Stack>

					<Stack px={16}>
						<Divider />
					</Stack>

					{/* Bottom Section */}
					<Stack
						pb={16}
						px={16}
						spacing={12}
					>
						<Stack spacing={8}>
							{menuItems.map((menuItem, index) => (
								<Menu.Item
									p={0}
									onClick={menuItem.onClick}
									key={index}>
									<Group
										className={classes.menuItem}
										spacing={8}>
										<FaDisplay
											containerSize={24}
											fontSize={16}
											faIcon={menuItem.icon}
										/>
										<Text typography='title-bold-16'>{menuItem.label}</Text>
									</Group>
								</Menu.Item>
							))}
						</Stack>

						<Divider />

						{/* Logout action */}
						<Group
							onClick={() => AuthUtils.revokeCurrentSession().catch(() => null).finally(() => navigate('/auth', {replace: true}))}
							className={classes.menuItem}
							spacing={8}>
							<FaDisplay
								color={theme.colors.light.error.default}
								containerSize={24}
								fontSize={16}
								faIcon={faSignOut}
							/>
							<Text
								color={theme.colors.light.error.default}
								typography='title-bold-16'>{t('menu.logout')}</Text>
						</Group>
					</Stack>

				</Stack>
			</Menu>
		</>
	);
};

export default NavUser;