import {useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

// Redux
import {store} from 'store';
import {clearUserData} from 'store/actions';

// Global utils
import AuthUtils from 'utils/auth';

// We will try to render the content first when sessionToken exist and then check if the user is authenticated afterwards
// This is done to smoothly transition in the content even the user's connection is slow
// If we decides to authenticate first, the transition will apply on the empty space while the user is being authenticated
const RequireAuthRoute = ({children, debug}) => {
	const isSessionExist = !!AuthUtils.sessionToken;
	// const isSessionExist = true;
	const navigate = useNavigate();
	const location = useLocation();
	// Lifecycle Hooks
	useEffect(() => {
		!debug && AuthUtils.isSessionValid().then(() => {
			if (!store.getState().reducerUser?.selectedLocation?.id) {
				throw new Error('NO_OUTLET_SELECTED');
			}
		}).catch(e => {
			if (e?.response?.status === 401) {
				AuthUtils.revokeCurrentSession().catch(() => null).finally(() => {
					navigate('/auth', {replace: true});
				});
			} else if (e?.message === 'TOKEN_DONT_EXIST') {
				navigate('/auth', {replace: true});
			} else if (e?.message === 'NO_OUTLET_SELECTED') {
				Cookies.remove(AuthUtils.sessionStorageKey);
				store.dispatch(clearUserData());
				setTimeout(() =>
					navigate('/auth', {replace: true})
				, 100);
			}
		});
	}, [location]);
	// Render
	if (debug || isSessionExist) {
		return children;
	} else if (isSessionExist === null) {
		return <></>;
	}
	return null;
};

RequireAuthRoute.defaultProps = {
	debug: false,
};

RequireAuthRoute.propTypes = {
	children: PropTypes.node,
	debug: PropTypes.bool,
};

export default RequireAuthRoute;
