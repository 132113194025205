import {createStyles} from '@mantine/core';

export const useStyles = createStyles((theme, {withoutTopBorder}) => ({
	container: {
		borderTop: withoutTopBorder ? 'unset' : `1px solid ${theme.colors.light.neutral.border}`,
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
		height: '100%',
	},
	inputContainer: {
		width: '100%',
	},
	buttonContainer: {
		flex: 1,
	},
}));