// Local Redux
import {SET_USER_DATA, CLEAR_USER_DATA, SET_SESSION} from '../actionTypes';

const initialState = {};

const reducerUser = (state = initialState, {type, data}) => {
	switch (type) {
		case SET_USER_DATA:
	  return {...state, ...data};
		case SET_SESSION:
			return {...state, session: data.session};
		case CLEAR_USER_DATA:
	  return initialState;
		default:
			return state;
	}
};

export default reducerUser;
