import {createStyles} from '@mantine/core';

export const useStyles = createStyles(() => ({
	header: {
		padding: '16px 24px',
	},
	column: {
		flex: 1,
		maxWidth: 'unset',
		display: 'flex',
		alignItems: 'center',

		'&:nth-of-type(2)': {
			flex: 2,
			justifyContent: 'center',
			minWidth: 440,
		},
		'&:last-child': {
			justifyContent: 'flex-end',
		},
	},
}));