import {createStyles} from '@mantine/core';

export const useStyles = createStyles(() => ({
	cards: {
		width: '100%',
		marginTop: 16,
	},
	select: {
		width: '100%',
	},
}));