import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {motion, AnimatePresence} from 'framer-motion';

// Global components
import Text from 'components/core/Text';

// Local styles
import {useStyles} from './styles';
import {useSelector} from 'react-redux';

import OfflineAnnouncer from 'assets/sounds/internet-offline-alert.mp3';
import useBellSound from 'hooks/useBellSound';

const ServiceStatus = () => {
	const {classes, cx} = useStyles();
	const {t} = useTranslation('translation', {keyPrefix: 'global.header.serviceStatus'});

	const isOnline = useSelector(({reducerOrder}) => reducerOrder?.connectedToServer) || null;

	const [previousValue, setPreviousValue] = useState(undefined);
	const [serviceStatusVisible, setServiceStatusVisible] = useState(false);

	const [playOfflineAnnouncer] = useBellSound(OfflineAnnouncer, false);

	useEffect(() => {
		setPreviousValue(isOnline);
		let timer;
		if (isOnline) {
			if (previousValue === false || previousValue === null) {
				setServiceStatusVisible(true);
				timer = setTimeout(() => {
					setServiceStatusVisible(false);
				}, 2000); // 2 seconds delay
			}
		} else {
			if (previousValue === true) {
				playOfflineAnnouncer();
			}
			setServiceStatusVisible(true);
		}

		return () => clearTimeout(timer);
	}, [isOnline]);

	return (
		<>
			<AnimatePresence>
				{
					serviceStatusVisible && (
						<motion.div
							initial={{height: 0, opacity: 0}}
							animate={{height: 'auto', opacity: 1}}
							exit={{height: 0, opacity: 0}}
						>
							<div
								className={cx(
									classes.container,
									{[classes['container--danger']]: !isOnline},
								)}>
								<Text typography='caption-medium-12'>
									{
										isOnline
											? t('frestoIsOnline')
											: t('frestoIsOffline')
									}
								</Text>
							</div>
						</motion.div>
					)
				}
			</AnimatePresence>
		</>
	);
};

export default ServiceStatus;