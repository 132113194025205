export const TIME_LIMIT = 300; //in seconds

export const ERROR_CODES = {
	AUTH_INVALID: 'auth.invalid',
	AUTH_LOCKED: 'auth.locked',
	AUTH_INACTIVE: 'auth.inactive',
};

export const ERROR_MESSAGE = {
	INVALID_UDER: 'User not found',
};

export const SUPPORTED_ORDER_PLATFORMS = {
	GRAB: 'Grab',
	GOJEK: 'Gojek',
	SHOPEE: 'Shopee',
	HANGRY_APP: 'Hangry App',
};

export const SUPPORTED_MENU_PLATFORMS = {
	GRAB: 'Grab',
	GOJEK: 'Gojek',
	SHOPEEE: 'Shopee',
	HANGRY_APP: 'Hangry App',
};

export const MERCHANT_APP_NAMES = {
	'Grab': 'Grab Merchant',
	'Gojek': 'GoBiz',
	'Shopee': 'Shopee Partner',
};

export const ORDER_STATUS = {
	// Pending & Pricessing
	ACCEPTED: 'accepted',
	DRIVER_ARRIVED: 'driverArrived',
	DRIVER_FOUND: 'driverFound',
	// History
	CANCELLED: 'cancelled',
	COLLECTED: 'collected',
	DELIVERED: 'delivered',
	FAILED: 'failed',
};

export const PROBLEMATIC_CODES = {
	DEFAULT_ERROR: 10,
	PRICE_MISMATCH: 11,
	ROBOT_OFFLINE: 12,
	POS_OFFLINE: 13,
	MENU_ERROR: 14,
};

export const REQUEST_STATUS = {
	PENDING: 'pending',
	FAILED: 'failed',
	CANCELLED: 'cancelled',
	SUCCESS: 'success',
	PROCESSED: 'processed',
};

export const MANAGE_BRAND = {
	MANAGE_ON: 'manage_on',
	MANAGE_OFF: 'manage_off',
};

export const POS_ORDER_TYPE = {
	DELIVERY: 'delivery',
	TAKEAWAY: 'takeaway',
};

export const ORDER_PAIR_TYPE = {
	MANUAL: 'manual',
	PSEUDO: 'force',
};

export const POS_PLATFORM_TYPE = {
	INTERNAL: 'internal',
	EXTERNAL: 'external',
};

export const POS_ORDER_STATUS = {
	PENDING: 'pending',
	PROCESSED: 'processed',
	DONE: 'done',
	VOID: 'void',
	CANCEL: 'cancelled',
};

export const POS_PAIR_STATUS = {
	PENDING: 'pending',
	PENDING_LATER: 'pending_later',
	DONE: 'done',
	MERGED: 'merged',
	VOID: 'void',
};

export const SYNC_SOCKET_EVENT = {
	recon_order_done: 'recon_order_done',
	quick_sync_done: 'quick_sync_done',
	manual_sync_done: 'manual_sync_done',
	auto_sync_done: 'auto_sync_done',
	auto_sync_start: 'auto_sync_start',
};

export const PAYMENT_METHOD_ACTION_TYPE = {
	NONE: 'none',
	TRACE_NUMBER: 'traceNumber',
	OTHER: 'other',
};

export const VOID_REASON = [
	{
		'value': 'DC-DTD',
		'desc': 'Driver/Pelanggan tidak datang',
	},
	{
		'value': 'DC-DCQ',
		'desc': 'Driver cancel karena antrian',
	},
	{
		'value': 'DSH',
		'desc': 'Double struk (Hangry App error system)',
	},
	{
		'value': 'DSF',
		'desc': 'Double struk fresto',
	},
	{
		'value': 'OOS',
		'desc': 'Menu OOS sudah terlanjur di-input',
	},
	{
		'value': 'KOL',
		'desc': 'Perubahan/Cancel KOL',
	},
	{
		'value': 'KSI',
		'desc': 'Kasir salah input',
	},
	{
		'value': 'PSP',
		'desc': 'Pelanggan salah pesan',
	},
];

export const POS_EVENTS = {
	SET_SALES_CHANNEL: 'setSalesChannel',
	SET_ORDER_TYPE: 'setOrderType',
	RESET_SALES_CHANNEL: 'resetSalesChannel',
	SAVE_SCHEDULE: 'saveSchedule',
	SAVE_ORDER: 'saveOrder',
	SAVE_PAYMENT: 'savePayment',
};