import {faArrowRotateRight} from '@fortawesome/pro-solid-svg-icons';
import {Box} from '@mantine/core';
import Button from 'components/core/Button';
import FaDisplay from 'components/others/FaDisplay';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {reprocessFailedStatusRequest} from 'request/menuAvailability';
import notification from 'utils/notification';
import {socket} from 'utils/socket';

const socketPayloadDefaultState = {
	toggleMenu: {
		on: {success: 0, failed: 0},
		off: {success: 0, failed: 0},
	},
	toggleComplex: {
		on: {success: 0, failed: 0},
		off: {success: 0, failed: 0},
	},
};

const OnOffMenuNotification = () => {
	const {t} = useTranslation('translation', {keyPrefix: 'global.header.onOffMenuNotification'});
	const [loadingRetry, setLoadingRetry] = useState(false);

	const getSuccessNotificationLocale = ({
		successOnMenuCount,
		successOffMenuCount,
		successOnComplexCount,
		successOffComplexCount,
	}) => {
		let requestType = null;

		const processedMenuCount = (successOnMenuCount + successOffMenuCount) || 0;
		const processedComplexCount = (successOnComplexCount + successOffComplexCount) || 0;

		if (processedMenuCount != 0 && processedComplexCount != 0) {
			requestType = 'menuAndComplex';
		} else if (processedMenuCount != 0) {
			requestType = 'menu';
		} else if (processedComplexCount != 0) {
			requestType = 'complex';
		}

		return {
			title: t(`${requestType}ProcessSuccessTitle`, {
				menuCount: processedMenuCount,
				complexCount: processedComplexCount,
			}),
			message: t(`${requestType}ProcessSuccessMessage`),
		};
	};

	const getFailedNotificationLocale = ({
		failedOnMenuCount, failedOffMenuCount, failedOnComplexCount, failedOffComplexCount,
	}) => {
		let requestType = null;

		const failMenuCount = (failedOnMenuCount + failedOffMenuCount) || 0;
		const failComplexCount = (failedOnComplexCount + failedOffComplexCount) || 0;

		if (failMenuCount != 0 && failComplexCount != 0) {
			requestType = 'menuAndComplex';
		} else if (failMenuCount != 0) {
			requestType = 'menu';
		} else if (failComplexCount != 0) {
			requestType = 'complex';
		}

		return {
			title: t(`${requestType}ProcessFailTitle`, {
				menuCount: failMenuCount,
				complexCount: failComplexCount,
			}),
			message: t(`${requestType}ProcessFailMessage`),
		};
	};

	const notificationHandler = (socketPayload = socketPayloadDefaultState) => {
		console.log(socketPayload, '<< event received');
		const {toggleMenu, toggleComplex} = socketPayload;

		const successOnMenuCount = toggleMenu?.on?.success || 0;
		const successOffMenuCount = toggleMenu?.off?.success || 0;
		const successOnComplexCount = toggleComplex?.on?.success || 0;
		const successOffComplexCount = toggleComplex?.off?.success || 0;

		const failedOnMenuCount = toggleMenu?.on?.failed || 0;
		const failedOffMenuCount = toggleMenu?.off?.failed || 0;
		const failedOnComplexCount = toggleComplex?.on?.failed || 0;
		const failedOffComplexCount = toggleComplex?.off?.failed || 0;

		if ([
			successOnMenuCount, successOffMenuCount, successOnComplexCount, successOffComplexCount,
			failedOnMenuCount, failedOffMenuCount, failedOnComplexCount, failedOffComplexCount,
		].some(result => result != 0)) {

			// Success toast
			if ([
				successOnMenuCount, successOffMenuCount, successOnComplexCount, successOffComplexCount,
			].some(result => result != 0)) {
				const {title, message} = getSuccessNotificationLocale({successOnMenuCount, successOffMenuCount, successOnComplexCount, successOffComplexCount});
				notification.success({
        	title,
					message,
				});
			}

			// Fail toast
			if ([
				failedOnMenuCount, failedOffMenuCount, failedOnComplexCount, failedOffComplexCount,
			].some(result => result != 0)) {
				const {title, message} = getFailedNotificationLocale({failedOnMenuCount, failedOffMenuCount, failedOnComplexCount, failedOffComplexCount});
				notification.error({
					id: 'errorOnOff',
					autoClose: false,
				});
				notification.error({
					isUpdate: true,
					id: 'errorOnOff',
					autoClose: false,
					title,
					message,
					extra: <Box mt={16}>
						<Button
							loading={loadingRetry}
							size="sm"
							leftIcon={(
								<FaDisplay
									containerSize={18}
									fontSize={14}
									faIcon={faArrowRotateRight} />
							)}
							onClick={async () => {
								try {
									setLoadingRetry(true);
									if (failedOnMenuCount || failedOffMenuCount) await reprocessFailedStatusRequest(false, false);
									if (failedOnComplexCount || failedOffComplexCount) await reprocessFailedStatusRequest(true, false);
									notification.hide('errorOnOff');
									notification.normal({
										title: t('reprocessTitle'),
										message: t('reprocessMessage'),
									});
								} catch (error) {

								} finally {
									setLoadingRetry(false);
								}
							}}
						>
							{t('retryText')}
						</Button>
					</Box>,
				});
			}
		}
	};

	useEffect(() => {
		socket.on('menu_availability_request', notificationHandler);
		return () => socket.off('menu_availability_request', notificationHandler);
	}, []);

	return null;
};

export default OnOffMenuNotification;