// Local Redux
import {SET_AUTO_SYNC_RUNNING, SET_QUICK_SYNC_RUNNING, SET_DEEP_SYNC_RUNNING, SET_MANUAL_SYNC_RUNNING} from '../actionTypes';

const initialState = {
	autoSyncRunning: false,
	deepSyncRunning: false,
	quickSyncRunning: false,
	manualSyncRunning: false,
};

const reducerBackgroundSync = (state = initialState, {type, data}) => {
	switch (type) {
		case SET_AUTO_SYNC_RUNNING:
			return {...state, autoSyncRunning: data.autoSyncRunning};
		case SET_DEEP_SYNC_RUNNING:
			return {...state, deepSyncRunning: data.deepSyncRunning};
		case SET_QUICK_SYNC_RUNNING:
			return {...state, quickSyncRunning: data.quickSyncRunning};
		case SET_MANUAL_SYNC_RUNNING:
			return {...state, manualSyncRunning: data.manualSyncRunning};
		default:
			return state;
	}
};

export default reducerBackgroundSync;