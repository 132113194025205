import {createStyles} from '@mantine/core';

export const useStyles = createStyles(theme => ({
	progressBar: {
		flex: 1,
	},
	iconContainer: {
		backgroundColor: theme.colors.light.info.background,
		borderRadius: '50%',
	},
}));