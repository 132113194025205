import {POS_ORDER_TYPE, SUPPORTED_ORDER_PLATFORMS} from './constants';
import {isEqualWith} from 'lodash';
import {store} from 'store';

export const calculateSubtotal = cart => {
	if (!Array.isArray(cart)) {
		// If cartItems is not an array, return 0 as the total quantity
		return 0;
	}

	const finalSubtotal = cart.reduce((acc, currentItem) => {
		let finalMenuPrice = Number(currentItem.listedPrice);

		currentItem.optionGroups.forEach(optionGroup => {
			optionGroup.options.forEach(childMenu => {
				finalMenuPrice += Number(childMenu.menuPrice);
			});
		});

		finalMenuPrice = finalMenuPrice * currentItem.quantity;

		return acc + finalMenuPrice;
	}, 0);

	return finalSubtotal;
};

export const getPlatformOrdePrefix = (platformLabel, orderType) => {
	switch (platformLabel) {
		case SUPPORTED_ORDER_PLATFORMS.GRAB:
			return 'GF-';
		case SUPPORTED_ORDER_PLATFORMS.GOJEK:
			return orderType === POS_ORDER_TYPE.DELIVERY ? 'F-XXXXX' : 'FP-XXX';
		case SUPPORTED_ORDER_PLATFORMS.SHOPEE:
			return 'SF';
		default:
			return '';
	}
};

export const checkCashMethod = obj => {
	if (!obj || typeof obj !== 'object') {
		return false;
	}
	// Check if the "methods" property exists and is an array
	if (!obj.hasOwnProperty('methods') || !Array.isArray(obj.methods)) {
		return false;
	}

	// Check if there is exactly one item in the "methods" array
	if (obj.methods.length !== 1) {
		return false;
	}

	// Check if the "type" of the only item in "methods" array is "Cash"
	if (obj.methods[0].hasOwnProperty('type') && obj.methods[0].type === 'Cash') {
		return true;
	}

	return false;
};

export const splitOrderId = (inputStr = '') => {
	const knownPrefixes = ['GF-', 'F-XXXXX', 'FP-XXX', 'SF-', 'SF', 'F-', 'FP-'];

	let foundPrefix = null;
	let rest = inputStr;

	// Iterate through the list of known prefixes
	for (const prefix of knownPrefixes) {
		if (inputStr?.startsWith(prefix)) {
			foundPrefix = prefix;
			rest = inputStr.slice(prefix.length);
			break;
		}
	}

	return {
		foundPrefix: foundPrefix,
		rest: rest,
	};
};

export const shouldShowEndDayReminder = () => {
	const END_DAY_REMINDER_HOURS_THRESHOLD = 28;

	const sessionData = store.getState().reducerUser?.session || {};
	const givenDate = new Date(sessionData.createdAt);
	const currentDate = new Date();

	const timeDifferenceInMillis = currentDate - givenDate;
	return (timeDifferenceInMillis / (1000 * 60 * 60)) >= END_DAY_REMINDER_HOURS_THRESHOLD;
};

export const areOptionsSimilar = (optionsA, optionsB) => {
	if (optionsA.length !== optionsB.length) {
		return false;
	}

	// On edit menu module, theres an additoinal field `menuStation` and need to be delete before compared with menu template data
	optionsA.forEach(option => delete option.menuStation);
	optionsB.forEach(option => delete option.menuStation);

	const sortedOptionsA = optionsA.slice().sort((a, b) => a.menuId - b.menuId);
	const sortedOptionsB = optionsB.slice().sort((a, b) => a.menuId - b.menuId);

	const customizer = (objValue, othValue, key) => {
		if (key === 'menuId') {
			return objValue == othValue;
		}
	};

	return isEqualWith(sortedOptionsA, sortedOptionsB, customizer);
};

export const areOptionGroupsSimilar = (groupsA, groupsB) => {
	if (groupsA.length !== groupsB.length) {
		return false;
	}

	const sortedGroupsA = groupsA.slice().sort((a, b) => a.optionGroupId - b.optionGroupId);
	const sortedGroupsB = groupsB.slice().sort((a, b) => a.optionGroupId - b.optionGroupId);

	for (let i = 0; i < sortedGroupsA.length; i++) {
		if (
			sortedGroupsA[i].optionGroupId !== sortedGroupsB[i].optionGroupId ||
					!areOptionsSimilar(sortedGroupsA[i].options, sortedGroupsB[i].options)
		) {
			return false;
		}
	}

	return true;
};

export const mergeMenuWithSameComplex = items => {
	const mergedItemsMap = new Map();

	items.forEach(item => {
		const key = JSON.stringify({
			notes: item.notes,
			menuId: item.menuId,
			optionGroups: item.optionGroups,
		});

		if (mergedItemsMap.has(key)) {
			const existingItem = mergedItemsMap.get(key);
			existingItem.quantity += item.quantity;
		} else {
			let foundSimilar = false;
			mergedItemsMap.forEach(value => {
				if (
					areOptionGroupsSimilar(item.optionGroups, value.optionGroups) &&
									item.notes === value.notes &&
									item.menuId === value.menuId
				) {
					value.quantity += item.quantity;
					foundSimilar = true;
				}
			});
			if (!foundSimilar) {
				mergedItemsMap.set(key, {...item});
			}
		}
	});

	const mergedItems = Array.from(mergedItemsMap.values());

	return mergedItems;
};
