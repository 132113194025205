import i18next from 'i18next';
import {faBadgePercent, faBook, faCashRegister, faCrown, faUser, faUtensils} from '@fortawesome/pro-regular-svg-icons';

export default () => ({
	MASTER: {icon: faCrown, key: 'syncMaster', label: i18next.t('pages.settings.master'), desc: i18next.t('pages.settings.masterDesc')},
	MENU: {icon: faBook, key: 'syncMenu', label: i18next.t('pages.settings.menu'), desc: i18next.t('pages.settings.menuDesc')},
	ORDER: {icon: faUtensils, key: 'syncOrder', label: i18next.t('pages.settings.order'), desc: i18next.t('pages.settings.orderDesc')},
	CAMPAIGN: {icon: faBadgePercent, key: 'syncCampaign', label: i18next.t('pages.settings.campaign'), desc: i18next.t('pages.settings.campaignDesc')},
	POS: {icon: faCashRegister, key: 'syncSetting', label: i18next.t('pages.settings.pos'), desc: i18next.t('pages.settings.posDesc')},
	USER: {icon: faUser, key: 'syncUser', label: i18next.t('pages.settings.user'), desc: i18next.t('pages.settings.userDesc')},
});