import {createStyles} from '@mantine/core';

export const useStyles = createStyles(theme => ({
	container: {
		borderTop: `1px solid ${theme.colors.light.neutral.border}`,
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
		height: '100%',
	},
}));