import lowerCase from 'lodash/lowerCase';
import isArray from 'lodash/isArray';
import words from 'lodash/words';
import size from 'lodash/size';
import get from 'lodash/get';

import i18next from 'i18next';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {POS_ORDER_STATUS, POS_PLATFORM_TYPE, SUPPORTED_ORDER_PLATFORMS} from './constants';
import notification from './notification';
import {isEmpty} from 'lodash';
import {getVoidFormQR} from 'request/pos';
import {store} from 'store';
import {setVoidQrModal} from 'store/actions';

const TEST_LABEL = '[TEST]';
const internalSalesChannelImg = 'https://api.dicebear.com/7.x/initials/svg?seed={{platformLabel}}&backgroundColor=e53935&fontFamily=Verdana&fontSize=51&fontWeight=600&chars={{platformChars}}';
const brandFallbackImage = require('assets/logos/brandsClean/Hangry App.png');

dayjs.extend(utc);
dayjs.extend(timezone);

export const passingProps = (props, whitelistProps, blacklistProps) => {
	return Object.keys(props).reduce((previousValue, currentValue) => {
		if (isArray(whitelistProps) && whitelistProps.includes(currentValue)) {
			return {...previousValue, [currentValue]: props[currentValue]};
		} else if (
			isArray(blacklistProps) &&
      !blacklistProps.includes(currentValue)
		) {
			return {...previousValue, [currentValue]: props[currentValue]};
		}
		return previousValue;
	}, {});
};

export const roundNumber = (number, decimals = 2) => {
	const parsedNumber = parseFloat(number);
	if (!isNaN(parsedNumber)) {
		return Math.round((parsedNumber + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals);
	} else {
		return number;
	}
};

export const numberFormat = amount => {
	const rounded = roundNumber(amount, 2);
	return rounded
		? rounded.toString()
			.replace(/\./g, ',') // Replace decimal point with comma
			.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
		: 0;
};

export const idrPrefix = amount => {
	const numberFormatted = numberFormat(amount);
	return 'Rp' + numberFormatted;
};

export const isEmailValid = (value = '') => {
	const regexPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return value.match(regexPattern);
};

export const cleanPathname = (pathname = '') => {
	return pathname.replace(/\/$/, '');
};

export const humanFileSize = (size = 0) => {
	if (size === 0) {
		return '0 B';
	}
	var i = Math.floor( Math.log(size) / Math.log(1024) );
	return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

export const downloadFile = file => {
	// Create a link and set the URL using `createObjectURL`
	const link = document.createElement('a');
	link.style.display = 'none';
	link.href = URL.createObjectURL(file);
	link.download = file.name;

	// It needs to be added to the DOM so it can be clicked
	document.body.appendChild(link);
	link.click();

	// To make this work on Firefox we need to wait
	// a little while before removing it.
	setTimeout(() => {
		URL.revokeObjectURL(link.href);
		link.parentNode.removeChild(link);
	}, 0);
};

export const lowercasedIncludes = (original = '', search = '') => {
	search = search?.toLowerCase?.() || search;
	if (typeof original === 'string') {
		return original?.toLowerCase?.().includes(search);
	} else if (Array.isArray(original)) {
		// Array provided includes only check for exact string from an array
		// We will extend the logic to check for substring matches if the orginial is array of strings
		return original.some(e => {
			const lowerCasedValue = e?.toLowerCase?.() || e;
			return typeof lowerCasedValue === 'string'
				? lowerCasedValue.includes(search)
				: lowerCasedValue === search;
		});
	} else {
		return false;
	}
};

export const getPlatformImage = (platform, isOfflinePlatform = false) => {
	const platformImage = (() => {
		try {
			return require(`assets/logos/platforms/${platform}.png`);
		} catch {
			return isOfflinePlatform
				? internalSalesChannelImg
					.replace('{{platformLabel}}', platform)
					.replace('{{platformChars}}', Math.min(size(words(platform)), 2))
				: require('assets/logos/platforms/Hangry App.png');
		}
	})();
	return platformImage;
};

export const getBrandImage = brand => {
	const brands = get(store.getState(), ['reducerOrder', 'brands'], []);

	const foundBrand = brands.find(brandData => brandData.label === brand);
	const brandImage = get(foundBrand, ['logoUrl'], null) || brandFallbackImage;

	return brandImage;
};

export const getOrderTitle = (data, isRecon = false) => {
	let title = '';
	const matchCase = data?.Merchant?.Platform?.label || data?.platformLabel;
	switch (matchCase) {
		case 'Grab':
		case 'Gojek':
			title = isRecon ? data.platformOrderId : data.merchantOrderId;
			if (matchCase === SUPPORTED_ORDER_PLATFORMS.GOJEK) title = title?.slice?.(-5);
			break;
		case 'Shopee': {
			const shopeeIdEntry = isRecon ? data.platformOrderId : data.merchantOrderId;
			const isTest = shopeeIdEntry?.includes?.(TEST_LABEL);
			title = `${isTest ? `${TEST_LABEL} ` : ''}#${(shopeeIdEntry?.split?.(' ')?.pop?.())?.slice(2)}`; break;
		}
		case 'Hangry App':
			title = data?.orderDetails?.customer?.name || data?.customer?.name || data?.merchantOrderId || data?.externalAltId;
			break;
		default:
			title = data.externalAltId || data.inputDetails?.customerName || data?.customer?.name; break;
	}
	return title;
};

export const getIndonesianTimezoneLabel = value => {
	// 0 is a valid value and are not replaced by default value
	let utcOffset = value || value === 0 ? value : dayjs().utcOffset();
	if (typeof value === 'string') {
		utcOffset = dayjs().tz(value).utcOffset();
	}
	const utcOffsetInHour = utcOffset / 60;
	switch (utcOffsetInHour) {
		case 7:
			return 'WIB';
		case 8:
			return 'WITA';
		case 9:
			return 'WIT';
		default:
			return typeof value === 'string'
				? value
				: `UTC${utcOffsetInHour >= 0 ? '+' : ''}${
            Math.round(utcOffsetInHour * 100) / 100
          }`;
	}
};

export const isInElectron = navigator.userAgent.toLowerCase().indexOf(' electron/') > -1;

export const checkOfflinePlatform = (platformData = {}) => {
	return platformData?.type === 'internal' && platformData?.label !== SUPPORTED_ORDER_PLATFORMS.HANGRY_APP;
};

export const errorSyncNotifier = (err, syncType) => {
	const errorCode = get(err, ['response', 'data', 'error', 'errorCode']);
	switch (errorCode) {
		case 'SYNC-001':
			notification.error({
				title: i18next.t('global.sync.Notification.syncIsRunning.title', {
					syncType: i18next.t('global.sync.syncType.autoSync'),
				}),
				message: i18next.t('global.sync.Notification.syncIsRunning.messageAuto'),
			});
			break;
		case 'SYNC-002':
			// This errorCode will always have syncType
			notification.error({
				title: i18next.t('global.sync.Notification.syncIsRunning.title', {
					syncType,
				}),
				message: i18next.t('global.sync.Notification.syncIsRunning.message', {
					syncType: lowerCase(syncType),
				}),
			});
			break;
		default:
			notification.error({
				title: i18next.t('global.sync.Notification.failed.title'),
				message: i18next.t('global.sync.Notification.failed.message'),
			});
			break;
	}
};

export const orderNeedToReimburse = ({
	order = {},
	includeUnpairedManualInput = false,
}) => {
	const isOfflinePlatform = order?.Merchant?.Platform?.type === POS_PLATFORM_TYPE.INTERNAL &&
	order?.Merchant?.Platform?.label !== SUPPORTED_ORDER_PLATFORMS.HANGRY_APP;

	const isCancel = order.status === POS_ORDER_STATUS.CANCEL;
	const isVoid = order.status === POS_ORDER_STATUS.VOID;

	if ((isCancel || isVoid) && !isOfflinePlatform) {
		const formSubmitted = get(order, ['reimbursement', 'formSubmitted'], false);
		if (formSubmitted) return false;

		const isManualInput = order.isManualInput;

		if (isManualInput) {
			// Only voided pseudo order can be reimburse
			const isPseudoOrder = order.isManualInput && isEmpty(order.merchantOrder) && order.merchantRefId;
			if (isPseudoOrder && order.status === POS_ORDER_STATUS.VOID) return true;

			if (includeUnpairedManualInput && !order.merchantRefId && isVoid) return true;
		} else {
			// All voided and cancelled Merchant order can be reimburse
			return true;
		}
	} else {
		return false;
	}
};

export const openQrVoidModal = async (orderData = {}, setLoading = () => null) => {
	try {
		setLoading(true);
		const response = await getVoidFormQR({orderId: orderData.id});
		if (response.success) {
			const shortId = get(orderData, 'merchantOrderId') || get(orderData, ['inputDetails', 'customerName']) || '';
			store.dispatch(setVoidQrModal({
				visible: true,
				formUrl: response.data.url,
				orderId: orderData.id,
				shortId,
			}));
		}
	} catch (err) {
		const httpStatusCode = get(err, ['response', 'status'], null);
		if (httpStatusCode === 503) {
			notification.error({
				title: i18next.t('global.qrModal.loadFailed.title'),
				message: i18next.t('global.qrModal.loadFailed.message'),
			});
		}
	} finally {
		setLoading(false);
	}
};

export const getIndividualItems = (data = {}) => {
	return data?.items?.reduce?.((accumulator, currentItem) => {
		for (let i = 0; i < currentItem.quantity; i++) {
			accumulator.push({...currentItem, quantity: 1});
		}
		return accumulator;
	}, []);
};

export const getReimbursementDeductionAmount = (data = {}, individualItems = []) => {
	const madeItem = get(data, ['reimbursement', 'completedItems'], []);
	const notMadeItemCount = individualItems?.length - madeItem?.length;

	if (notMadeItemCount > 0) {
		let deductionAmount = 0;

		individualItems.forEach((item, index) => {
			if (madeItem.includes(index)) return;
			else {
				// eslint-disable-next-line react/prop-types
				deductionAmount += item.listedPrice;

				// eslint-disable-next-line react/prop-types
				const flattenOptions = item.optionGroups.reduce((acc, group) => acc.concat(group?.options || []), []);

				if (flattenOptions.length) {
					flattenOptions.forEach(option => {
						deductionAmount += option?.menuPrice;
					});
				}
			}
		});

		return deductionAmount;
	} else {
		return 0;
	}
};

export const getOrderItemCount = (items = []) => {
	return items?.reduce?.((totalQuantity, item) => {
		return totalQuantity + item.quantity;
	}, 0);
};

export const getGojekPin = order => {
	return order?.driver?.pin || order?.orderDetails?.driver?.pin || order?.merchantRefId || null;
};

export const looseSearch = (str, search) => {
	const keywords = search.split(' ');

	for (const keyword of keywords) {
		const regexp = new RegExp(keyword, 'i');
		if (!regexp.test(str)) return false; // Return false if any keyword is not found
	}

	return true; // Return true only if all keywords are found
};