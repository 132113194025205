import {Checkbox, Group, Stack} from '@mantine/core';
import PropTypes from 'prop-types';

// Fontawesome
import {faQuestionCircle} from '@fortawesome/pro-solid-svg-icons';

// Global components
import Text from 'components/core/Text';
import FaDisplay from 'components/others/FaDisplay';

// Local styles
import {useStyles} from './styles';

const CheckboxRadioCard = ({type, selected, disabled, faIcon, faIconColor, title, subtitle, onClick, children}) => {
	const {classes, cx, theme} = useStyles();

	// Render
	return (
		<Stack
			p={16}
			spacing={12}
			align="center"
			className={cx(classes.checkboxRadioContainer, {[classes.checkboxRadioContainerActive]: selected, [classes.checkboxRadioContainerDisabled]: disabled})}
			onClick={onClick}>
			<Group
				spacing={16}
				className={classes.checkboxRadioInnerContainer}>
				{
					faIcon && (
						<FaDisplay
							faIcon={faIcon}
							containerSize={28}
							fontSize={24}
							color={faIconColor}
							className={classes.icon} />
					)
				}
				<Stack
					spacing={2}
					className={classes.checkboxRadioText}>
					<Text
						typography="title-bold-14"
						color={!disabled ? theme.colors.light.text.primary : theme.colors.light.text.tertiary}>{title}</Text>
					<Text
						typography="caption-medium-12"
						color={!disabled ? theme.colors.light.text.secondary : theme.colors.light.text.disabled}>{subtitle}</Text>
				</Stack>
				<Checkbox
					checked={selected}
					classNames={{
						input: cx(classes.checkboxRadioInput, type === 'radio' && classes.checkboxRadioInputRounded),
						icon: classes.checkboxRadioIcon,
					}}
					readOnly />
			</Group>
			{children}
		</Stack>
	);
};

CheckboxRadioCard.defaultProps = {
	type: 'radio',
	selected: false,
	disabled: false,
	faIcon: faQuestionCircle,
	title: '',
	subtitle: '',
	onClick: () => null,
};

CheckboxRadioCard.propTypes = {
	type: PropTypes.oneOf(['radio', 'checkbox']),
	selected: PropTypes.bool,
	disabled: PropTypes.bool,
	faIcon: PropTypes.object,
	faIconColor: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	onClick: PropTypes.func,
	children: PropTypes.node,
};

export default CheckboxRadioCard;
