// Global utils
import AuthUtils from 'utils/auth';

// Redux
import {store} from 'store';

// Request utils
import request, {REQUEST_ENV} from 'request';

export const getPosPlatforms = () => {
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	return request(
		{
			method: 'get',
			url: `${REQUEST_ENV.POS_API_HOST}/master/platforms`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			params: {
				locationId,
			},
		},
	);
};

export const getPosSalesChannel = () => {
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	return request(
		{
			method: 'get',
			url: `${REQUEST_ENV.POS_API_HOST}/master/sales-channels`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			params: {
				locationId,
			},
		},
	);
};

export const getMenuTemplate = (menuTemplateId, platformId) => {
	return request(
		{
			method: 'get',
			url: `${REQUEST_ENV.POS_API_HOST}/master/menu-templates`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			params: {
				menuTemplateId,
				platformId,
			},
		},
	);
};

export const processPosPayment = async payload => {
	// payload: {locationId, merchantId, orderType, paymentNumber, subtotal, merchantOrderId, customerName, paymentId, items}
	return request({
		method: 'post',
	  url: `${REQUEST_ENV.POS_API_HOST}/order/submit`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
	  data: payload,
	});
};

export const updatePosMenuTemplate = async () => {
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.POS_API_HOST}/sync/menu-templates`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: {locationId},
		},
	);
};

export const updatePosMasterMenu = async () => {
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.POS_API_HOST}/sync/master-menus`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: {locationId},
		},
	);
};

export const getSyncData = async () => {
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	return request(
		{
			method: 'get',
			url: `${REQUEST_ENV.POS_API_HOST}/sync/data`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			params: {
				locationId,
			},
		},
	);
};

export const syncPosData = async ({options}) => {
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	return request(
		{
			method: 'post',
			url: `${REQUEST_ENV.POS_API_HOST}/sync/data`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: {locationId, options},
		},
	);
};

export const reconOrder = async ({brandIds}) => {
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	return request(
		{
			method: 'post',
			url: `${REQUEST_ENV.POS_API_HOST}/recon/order`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: {locationId, brandIds},
		},
	);
};

export const getSessionSummary = async ({sessionId}) => {
	return request(
		{
			method: 'post',
			url: `${REQUEST_ENV.POS_API_HOST}/session/print`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: {sessionId},
		},
	);
};

export const getShiftSummary = async ({shiftId}) => {
	return request(
		{
			method: 'post',
			url: `${REQUEST_ENV.POS_API_HOST}/session/shift/print`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: {shiftId},
		},
	);
};

export const reprintBill = async payload => {
	// payload: {id, templates}
	return request(
		{
			method: 'post',
			url: `${REQUEST_ENV.POS_API_HOST}/order/reprint`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
	);
};

export const testPrintInternalBill = async payload => {
	return request(
		{
			method: 'post',
			url: `${REQUEST_ENV.POS_API_HOST}/order/test-print`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
	);
};

export const getFreeItem = async cartItem => {
	return request(
		{
			method: 'post',
			url: `${REQUEST_ENV.POS_API_HOST}/promo/free-item`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: cartItem,
		},
	);
};

export const reimburseItem = async payload => {
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.POS_API_HOST}/order/reimbursement`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
	);
};

export const getVoidFormQR = async payload => {
	return request(
		{
			method: 'GET',
			url: `${REQUEST_ENV.POS_API_HOST}/order/reimbursement/qr`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			params: payload,
		},
	);
};

export const doneFillReimbursementForm = async payload => {
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.POS_API_HOST}/order/reimbursement/done`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
	);
};

export const updateOrderMenu = async payload => {
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.POS_API_HOST}/order/edit`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
	);
};

export const updatePayment = async payload => {
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.POS_API_HOST}/order/edit/payment`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
	);
};
