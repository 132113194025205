import {combineReducers} from 'redux';

import reducerOrder from './reducerOrder';
import reducerUser from './reducerUser';
import reducerRemoteConfig from './reducerRemoteConfig';
import reducerMenu from './reducerMenu';
import reducerElectron from './reducerElectron';
import reducerOutlet from './reducerOutlet';
import reducerPos from './reducerPos';
import reducerBackgroundSync from './reducerBackgroundSync';
import reducerVoidQrModal from './reducerVoidQrModal';

export default combineReducers({
	reducerOrder,
	reducerUser,
	reducerRemoteConfig,
	reducerMenu,
	reducerOutlet,
	reducerElectron,
	reducerPos,
	reducerBackgroundSync,
	reducerVoidQrModal,
});
