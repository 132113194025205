// Global utils
import AuthUtils from 'utils/auth';

// Redux
import {store} from 'store';

// Request utils
import request, {REQUEST_ENV} from 'request';

export const getMenuAvailabilityRequest = async (payload, setState) => {
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	// payload: {locationId, isChildMenu, searchString, brandLabel, isActive, sortBy, sortOrder, limit, offset}
	return request({
		method: 'get',
		url: `${REQUEST_ENV.ORDERHUB_API_HOST}/menu-availability/list`,
		headers: {
	    Authorization: `Bearer ${AuthUtils.sessionToken}`,
	  },
		params: {
			locationId,
			offset: 0,
			limit: 15,
			sortBy: 'menuLabel',
			sortOrder: 'asc',
			...payload,
		},
	}, setState);
};

export const getMaterialListRequest = async (payload, setState) => {
	// payload: {id, materialLabel, materialCode, createdBy, createdAtFrom, createdAtTo, sortBy, sortOrder, limit, offset}
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	return request({
		method: 'get',
		url: `${REQUEST_ENV.ORDERHUB_API_HOST}/menu-availability/material`,
		headers: {
	    Authorization: `Bearer ${AuthUtils.sessionToken}`,
	  },
		params: {
			locationId,
			offset: 0,
			limit: 15,
			sortBy: 'materialLabel',
			sortOrder: 'asc',
			...payload,
		},
	}, setState);
};

export const getLinkedMenuRequest = async (payload, setState) => {
	// payload: {queries: [{locationId, menuAvailabilityId} or {locationId, menuId, brandId}, ...]}
	return request({
		method: 'get',
		url: `${REQUEST_ENV.ORDERHUB_API_HOST}/menu-availability/menu/linked`,
		headers: {
	    Authorization: `Bearer ${AuthUtils.sessionToken}`,
	  },
		params: payload,
		timeout: 60000,
	}, setState);
};

export const updateMenuAvailabilityRequest = async (details, setState) => {
	// payload: {locationId, details: [{menuAvailabilityId, isActive, notes}]}
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	return request({
		method: 'post',
		url: `${REQUEST_ENV.ORDERHUB_API_HOST}/menu-availability/request/create`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
	  },
		data: {
			locationId,
			details,
		},
	}, setState);
};

export const updateMenuAvailabilityValidity = async details => {
	// payload: {locationId, details: [{menuAvailabilityId, isActive, notes}]}
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	return request({
		method: 'post',
		url: `${REQUEST_ENV.ORDERHUB_API_HOST}/menu-availability/request/check-menus`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
	  },
		data: {
			locationId,
			details,
		},
	});
};

export const getRequestStatusRequest = async (payload, setState) => {
	// payload: {locationId, isChildMenu, brandLabel: [], platformLabel: [], status: [], updateTo: [], limit, offset}
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	return request({
		method: 'get',
		url: `${REQUEST_ENV.ORDERHUB_API_HOST}/menu-availability/request`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
		params: {
			locationId,
			offset: 0,
			limit: 15,
			...payload,
		},
	}, setState);
};

export const reprocessFailedStatusRequest = async (isChildMenu, countOnly = true, setState) => {
	// payload: {locationId, isChildMenu, countOnly}
	// countOnly: true => get count
	// countOnly: false => reprocess failed requests
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	return request({
		method: 'post',
		url: `${REQUEST_ENV.ORDERHUB_API_HOST}/menu-availability/request/reprocess`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
		data: {
			locationId,
			isChildMenu,
			countOnly,
		},
	}, setState);
};