import {Group, Modal} from '@mantine/core';
import PropTypes from 'prop-types';

// Fontawesome
import {faCheckCircle, faExclamationCircle} from '@fortawesome/pro-solid-svg-icons';

// Global components
import Text from 'components/core/Text';
import Button from 'components/core/Button';
import FeaturedIcon from 'components/others/FeaturedIcon';

// Local styles
import {useStyles} from './styles';

const ICONS = {
	primary: faExclamationCircle,
	success: faCheckCircle,
	warning: faExclamationCircle,
	error: faExclamationCircle,
};

const ConfirmationModal = ({
	opened, loading, withoutIcon, singleButtonMode, align,
	title, subtitle, state, faIcon, children,
	cancelLabel, confirmLabel, primaryButtonProps, secondaryButtonProps,
	onClickCancel, onClickConfirm, onClose,
	...rest
}) => {
	const {classes, cx, theme} = useStyles();

	// Render
	return (
		<Modal
			centered
			opened={opened}
			withCloseButton={false}
			classNames={{
				modal: classes.modalCard,
				body: cx(classes.modalBody, {[classes[`modalBody--${align}`]]: true}),
			}}
			onClose={() => !loading ? onClose() : null}
			{...rest}
		>
			{!withoutIcon && (
				<FeaturedIcon
					mb={16}
					size="lg"
					color={state}
					type="normal"
					faIcon={faIcon || ICONS[state] || ICONS.primary} />
			)}
			<Text
				align={align}
				typography="title-bold-18"
				className={classes.formatting}
				dangerouslySetInnerHTML={{__html: title}} />
			{!!subtitle && (
				<Text
					mt={4}
					align={align}
					typography="body-regular-14"
					className={classes.formatting}
					color={theme.colors.light.text.secondary}
					dangerouslySetInnerHTML={{__html: subtitle}} />
			)}
			{children}
			<Group
				grow
				mt={32}
				className={classes.buttons}>
				{!singleButtonMode && (
					<Button
						size="lg"
						variant="outline"
						onClick={!loading ? onClickCancel : undefined}
						{...secondaryButtonProps}>
						{cancelLabel}
					</Button>
				)}
				<Button
					size="lg"
					loading={loading}
					onClick={onClickConfirm}
					{...primaryButtonProps}>
					{confirmLabel}
				</Button>
			</Group>
		</Modal>
	);
};

ConfirmationModal.defaultProps = {
	title: 'Title',
	align: 'center',
	state: 'primary',
	cancelLabel: 'Cancel',
	confirmLabel: 'Confirm',
	primaryButtonProps: {},
	secondaryButtonProps: {},
	onClickCancel: () => null,
	onClickConfirm: () => null,
	onClose: () => null,
};

ConfirmationModal.propTypes = {
	opened: PropTypes.bool,
	loading: PropTypes.bool,
	withoutIcon: PropTypes.bool,
	singleButtonMode: PropTypes.bool,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
	state: PropTypes.oneOf(['primary', 'success', 'warning', 'error']),
	faIcon: PropTypes.object,
	cancelLabel: PropTypes.string,
	confirmLabel: PropTypes.string,
	primaryButtonProps: PropTypes.object,
	secondaryButtonProps: PropTypes.object,
	onClickCancel: PropTypes.func,
	onClickConfirm: PropTypes.func,
	onClose: PropTypes.func,
	children: PropTypes.node,
};

export default ConfirmationModal;
