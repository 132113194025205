// Global utils
import AuthUtils from 'utils/auth';

// Request utils
import request, {REQUEST_ENV} from 'request';

export const startDay = async payload => {
	// payload: {cashStart}
	return request({
		method: 'post',
	  url: `${REQUEST_ENV.POS_API_HOST}/session/start`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
	  data: payload,
	});
};

export const endDay = async payload => {
	// payload: {cashEnd}
	return request({
		method: 'put',
	  url: `${REQUEST_ENV.POS_API_HOST}/session/end`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
	  data: payload,
	});
};

export const getSessionDetail = async params => {
	// params: {sessionId}
	return request({
		method: 'get',
	  url: `${REQUEST_ENV.POS_API_HOST}/session/detail`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
	  params,
	});
};

export const endShift = () => {
	return request({
		method: 'put',
	  url: `${REQUEST_ENV.POS_API_HOST}/session/shift/end`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
	});
};

export const getCurrentSession = async () => {
	return request({
		method: 'get',
	  url: `${REQUEST_ENV.POS_API_HOST}/session/active`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
	});
};

export const getSessionSalesRecap = async params => {
	// params: {sessionId}
	return request({
		method: 'get',
	  url: `${REQUEST_ENV.POS_API_HOST}/session/sales`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
		params,
	});
};

export const getSessionHistory = async params => {
	// params: {from, to}
	return request({
		method: 'get',
	  url: `${REQUEST_ENV.POS_API_HOST}/session/list`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
		params,
	});
};