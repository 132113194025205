export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_SESSION = 'SET_SESSION';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const SET_IS_SERVER_ONLINE = 'SET_IS_SERVER_ONLINE';
export const SET_ACTIVE_ORDERS = 'SET_ACTIVE_ORDERS';
export const SET_IS_FETCHING_ACTIVE_ORDER = 'SET_IS_FETCHING_ACTIVE_ORDER';
export const SET_HISTORY_DATE = 'SET_HISTORY_DATE';
export const SET_HISTORY_ORDERS = 'SET_HISTORY_ORDERS';
export const SET_IS_FETCHING_HISTORY_ORDER = 'SET_IS_FETCHING_HISTORY_ORDER';
export const SET_ORDER_DRAWER_DATA = 'SET_ORDER_DRAWER_DATA';
export const CLEAR_ORDER_DRAWER_DATA = 'CLEAR_ORDER_DRAWER_DATA';
export const SET_PLATFORMS = 'SET_PLATFORMS';
export const SET_BRANDS = 'SET_BRANDS';
export const SET_REMOTE_CONFIG = 'SET_REMOTE_CONFIG';
export const SET_TURN_OFF_REASONS = 'SET_TURN_OFF_REASONS';
export const SET_ELECTRON_DATA = 'SET_ELECTRON_DATA';
export const CLEAR_ELECTRON_DATA = 'CLEAR_ELECTRON_DATA';
export const SET_OUTLET_ON_OFF_REASONS_DURATION = 'SET_OUTLET_ON_OFF_REASONS_DURATION';
export const SET_MENU_TEMPLATE = 'SET_MENU_TEMPLATE';
export const SET_CART = 'SET_CART';
export const SET_LOADING_PUNCHIN = 'SET_LOADING_PUNCHIN';
export const SET_VISIT_PURPOSE = 'SET_VISIT_PURPOSE';
export const SET_ORDER_ORDER_ID = 'SET_ORDER_ORDER_ID';
export const SET_ORDER_CUSTOMER_NAME = 'SET_ORDER_CUSTOMER_NAME';
export const SET_ORDER_VOUCHERS = 'SET_ORDER_VOUCHERS';
export const SET_PAYMENT_LIST = 'SET_PAYMENT_LIST';
export const SET_CASH_AMOUNT = 'SET_CASH_AMOUNT';
export const SET_PAYMENT = 'SET_PAYMENT';
export const SET_SALES_TYPE = 'SET_SALES_TYPE';
export const ADD_CART_ITEM = 'ADD_CART_ITEM';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const CLEAR_PAYMENT = 'CLEAR_PAYMENT';
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export const CLEAR_CART_ITEM = 'CLEAR_CART_ITEM';
export const CLEAR_ORDER_DETAILS = 'CLEAR_ORDER_DETAILS';
export const CLEAR_POS_DATA = 'CLEAR_POS_DATA';
export const SET_SYNCING_SAPU_SAPU = 'SET_SYNCING_SAPU_SAPU';
export const SET_CONNECTED_TO_SERVER = 'SET_CONNECTED_TO_SERVER';
export const SET_FREE_ITEMS = 'SET_FREE_ITEMS';
export const CLEAR_FREE_ITEMS = 'CLEAR_FREE_ITEMS';
export const SET_ADDITIONAL_INFO = 'SET_ADDITIONAL_INFO';
export const CLEAR_ADDITIONAL_INFO = 'CLEAR_ADDITIONAL_INFO';
export const SET_AUTO_SYNC_RUNNING = 'SET_AUTO_SYNC_RUNNING';
export const SET_QUICK_SYNC_RUNNING = 'SET_QUICK_SYNC_RUNNING';
export const SET_DEEP_SYNC_RUNNING = 'SET_DEEP_SYNC_RUNNING';
export const SET_MANUAL_SYNC_RUNNING = 'SET_MANUAL_SYNC_RUNNING';
export const SET_VOID_QR_MODAL = 'SET_VOID_QR_MODAL';
export const CLEAR_VOID_QR_MODAL = 'CLEAR_VOID_QR_MODAL';
export const INITIATE_POS_EVENT = 'INITIATE_POS_EVENT';
export const ADD_POS_EVENT = 'ADD_POS_EVENT';