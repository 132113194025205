import {createStyles} from '@mantine/core';

export const useStyles = createStyles(theme => ({
	card: {
		borderRadius: 12,
		backgroundColor: theme.colors.light.modal.overlayWhite,
		border: `1px solid ${theme.colors.light.neutral.border}`,
		display: 'flex',
		flexDirection: 'column',
	},
	cardGrow: {
		flex: 1,
	},
	header: {
		borderBottom: `1px solid ${theme.colors.light.neutral.border}`,
	},
	content: {
		flex: 1,
		position: 'relative',
	},
	footer: {
		borderTop: `1px solid ${theme.colors.light.neutral.border}`,
	},
}));