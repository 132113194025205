// Local Redux
import {SET_VOID_QR_MODAL, CLEAR_VOID_QR_MODAL} from '../actionTypes';

const initialState = {
	visible: false,
	formUrl: '',
	orderId: null,
	shortId: null,
};

const reducerVoidQrModal = (state = initialState, {type, data}) => {
	switch (type) {
		case SET_VOID_QR_MODAL:
	  return {...state, ...data};
		case CLEAR_VOID_QR_MODAL:
			return initialState;
		default:
			return state;
	}
};

export default reducerVoidQrModal;
