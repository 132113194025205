import {createStyles} from '@mantine/core';

export const useStyles = createStyles(theme => ({
	wrapper: {
		cursor: 'pointer',
		padding: '4px 8px',
		borderRadius: '40px',
		border: '1px solid',
		backgroundColor: theme.colors.light.neutral.base,
		borderColor: theme.colors.light.neutral.border,
		color: theme.colors.light.text.secondary,
	},
	allCloseState: {
		backgroundColor: theme.colors.light.primary.background,
		borderColor: theme.colors.light.error.border,
		color: theme.colors.light.error.default,
	},
	someCloseState: {
		backgroundColor: theme.colors.light.primary.background,
		borderColor: theme.colors.light.error.border,
		color: theme.colors.light.error.default,
	},
	somePendingState: {
		backgroundColor: theme.colors.light.info.background,
		borderColor: theme.colors.light.info.border,
		color: theme.colors.light.info.default,
	},
}));