import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {setUserData} from 'store/actions';

import {Group, Modal, Stack} from '@mantine/core';

// Global components
import SearchInput from 'components/core/Input/SearchInput';
import Button from 'components/core/Button';
import Text from 'components/core/Text';

// Local styles
import {useStyles} from './styles';
import {lowercasedIncludes} from 'utils';

const ChangeOutletModal = ({changeOutletModalOpen, setChangeOutletModalOpen}) => {
	const {t} = useTranslation('translation', {keyPrefix: 'global.header'});
	const {details} = useSelector(({reducerUser}) => reducerUser) || {};

	const dispatch = useDispatch();
	const {classes} = useStyles();

	const [search, setSearch] = useState('');

	const displayedOutlets = details?.locations?.filter(location => lowercasedIncludes(location.label, search)) || [];

	return (
		<Modal
			centered
			opened={changeOutletModalOpen}
			withCloseButton={false}
			classNames={{
				modal: classes.modalCard,
				body: classes.modalBody,
			}}
			onClose={() => setChangeOutletModalOpen(false)}>
			<Text
				mt={24}
				typography="title-bold-18">
				{t('changeOutlet')}
			</Text>
			<SearchInput
				mt={16}
				width={352}
				placeholer={t('searchOutletPlacheholder')}
				onChangeSearch={setSearch}
			/>
			<Stack
				mt={12}
				spacing={0}
				className={classes.changeOutletContainer}>
				{displayedOutlets.map(location => (
					<Group
						py={8}
						key={location.id}
						position="apart"
						className={classes.changeOutletRow} >
						<Text
							typography="body-regular-16">
							{t('outlet', {name: location.label})}
						</Text>
						<Button onClick={() => {
							dispatch(setUserData({selectedLocation: location}));
							setTimeout(() =>
								window.location.reload()
							, 100);
						}}>
							{t('chooseOutlet')}
						</Button>
					</Group>
				))}
			</Stack>
		</Modal>
	);
};

ChangeOutletModal.defaultProps = {
	changeOutletModalOpen: false,
	setChangeOutletModalOpen: () => null,
};

ChangeOutletModal.propTypes = {
	changeOutletModalOpen: PropTypes.bool,
	setChangeOutletModalOpen: PropTypes.func,
};

export default ChangeOutletModal;