import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {setAutoSyncRunning, setDeepSyncRunning, setManualSyncRunning, setQuickSyncRunning} from 'store/actions';
import capitalize from 'lodash/capitalize';

// Global components
import SyncOverlay from '../SyncOverlay';

import generateSyncOptions from './generateSyncOptions';

// Utils
import {socket} from 'utils/socket';
import notification from 'utils/notification';
import {SYNC_SOCKET_EVENT} from 'utils/constants';

import {isEmpty} from 'lodash';

const SyncHandler = () => {
	const {t} = useTranslation('translation', {keyPrefix: 'global.sync'});

	const dispatch = useDispatch();

	const doneProgressRef = useRef({});
	const progressIntervalRef = useRef(null);

	const [checkedOptions, setCheckedOptions] = useState([]);
	const [isSyncing, setIsSyncing] = useState(false);
	const [progressBarPercent, setProgressBarPercent] = useState(0);
	const [titleAction, setTitleAction] = useState('');

	const syncOptions = Object.values(generateSyncOptions());

	const startSync = ({selectedOptions = [], titleAction = ''}) => {
		let tempDoneProgressRef = {};
		selectedOptions.forEach(key => {
			tempDoneProgressRef[key] = false;
		});

		doneProgressRef.current = tempDoneProgressRef;

		setCheckedOptions(selectedOptions);
		setIsSyncing(true);
		setTitleAction(titleAction);
	};

	const finishSync = () => {
		dispatch(setManualSyncRunning({manualSyncRunning: false}));
		setIsSyncing(false);
		setCheckedOptions([]);
		setProgressBarPercent(0);
		setTitleAction('');
		doneProgressRef.current = {};

		clearInterval(progressIntervalRef.current);

		window?.fetchPlatformAndBrands?.();
	};

	const updateProgress = () => {
		if (progressBarPercent < 100) {
			const progressArray = Object.values(doneProgressRef.current);
			const trueCount = progressArray.filter(value => value === true).length;
			const syncPercentage = (trueCount / progressArray.length) * 100;

			if (syncPercentage >= 100) {
				setProgressBarPercent(100);
			} else {
				const minProgress = ((((trueCount + 1) / progressArray.length) * 100) - 1);
				const barThreshold = Math.min(syncPercentage, 100);

				setProgressBarPercent(prevProgress => {
					if (trueCount) {
						return prevProgress < minProgress
							? Math.min((prevProgress + 10), minProgress, 100)
							: barThreshold == 100
								? barThreshold
								: prevProgress < (barThreshold - 1)
									? Math.min(prevProgress + 10, (barThreshold - 1))
									: prevProgress + 0;
					} else {
						return prevProgress < minProgress
							? Math.min(prevProgress + 10, minProgress)
							: prevProgress + 0;
					}
				});
			}

		} else {
			finishSync();
		}
	};

	const socketHandler = doneKeys => {
		if (doneProgressRef?.current?.hasOwnProperty?.(doneKeys)) {
			doneProgressRef.current[doneKeys] = true;
		}
	};

	const backgroundSyncHandler = event => {
		switch (event) {
			case SYNC_SOCKET_EVENT.recon_order_done:
				dispatch(setDeepSyncRunning({deepSyncRunning: false}));
				notification.success({
					title: t('Notification.success.title', {
						syncType: capitalize(t('syncType.deepSync')),
					}),
				});
				break;
			case SYNC_SOCKET_EVENT.quick_sync_done:
				dispatch(setQuickSyncRunning({quickSyncRunning: false}));
				notification.success({
					title: t('Notification.success.title', {
						syncType: capitalize(t('syncType.quickSync')),
					}),
				});
				break;
			case SYNC_SOCKET_EVENT.auto_sync_done:
				if (!isEmpty(doneProgressRef.current)) finishSync();
				dispatch(setAutoSyncRunning({autoSyncRunning: false}));
				notification.success({
					title: t('Notification.success.title', {
						syncType: capitalize(t('syncType.autoSync')),
					}),
				});
				break;
			case SYNC_SOCKET_EVENT.auto_sync_start:
				dispatch(setAutoSyncRunning({autoSyncRunning: true}));
				break;
			default:
				console.log(event);
				break;
		}
	};

	useEffect(() => {
		if (isSyncing) {
			progressIntervalRef.current = setInterval(updateProgress, 300);
		}

		return () => clearInterval(progressIntervalRef.current);
	}, [isSyncing, progressBarPercent]);

	useEffect(() => {
		window.startSync = startSync;

		const listener = (eventName, ...args) => {
			if (['sync_success', 'sync_failed'].some(handleEvent => handleEvent === eventName)) {
				socketHandler(args);
			}

			if (SYNC_SOCKET_EVENT.manual_sync_done === eventName) {
				finishSync();
				notification.success({
					title: t('Notification.success.title', {
						syncType: t('syncType.manualSync'),
					}),
				});
			}

			// Filter manual sync done, because it handled differently
			const filteredEvent = Object.values(SYNC_SOCKET_EVENT)
				.filter(syncEvent => syncEvent !== SYNC_SOCKET_EVENT.manual_sync_done);

			if (filteredEvent.some(handleEvent => handleEvent === eventName)) {
				filteredEvent.forEach(syncEvent => {
					socket.on(syncEvent, () => {
						backgroundSyncHandler(syncEvent);
					});
				});
			}
		};

		socket.onAny(listener);

		return () => {
			socket.offAny(listener);
		};
	}, []);

	return (
		<SyncOverlay
			visible={isSyncing}
			progressBarPercent={Number(progressBarPercent?.toFixed(0))}
			items={syncOptions.filter(item => checkedOptions.includes(item.key))}
			titleAction={titleAction}
		/>
	);
};

export default SyncHandler;