import React from 'react';
import {Stack} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import {useSelector} from 'react-redux';

// Fontawesome
import {faPowerOff} from '@fortawesome/pro-regular-svg-icons';

// Local styles
import {useStyles} from './styles';

// Local components
import DayCard from '../components/DayCard';

const EndDay = () => {
	const navigate = useNavigate();
	const {classes, theme} = useStyles();
	const {t} = useTranslation('translation', {keyPrefix: 'pages.shift.end'});
	const allowPos = useSelector(({reducerElectron}) => reducerElectron.allowPos);

	const goToShift = () => {
		navigate('/shift');
	};

	return (
		<Stack className={classes.container}>
			<DayCard
				faIcon={faPowerOff}
				iconColor={theme.colors.light.error.default}
				iconBgColor={theme.colors.light.primary.background}
				title={t('title')}
				subtitle={t(allowPos ? 'subtitle' : 'nonPosSubtitle')}
				actionBtnLabel={t('action')}
				onClickActionBtn={goToShift}
			/>
		</Stack>
	);
};

export default EndDay;