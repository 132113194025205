import {createStyles} from '@mantine/core';

export const useStyles = createStyles(theme => ({
	modalCard: {
		width: 400,
		padding: '24px !important',
	},
	modalBody: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	'modalBody--left': {
		alignItems: 'flex-start',
	},
	'modalBody--right': {
		alignItems: 'flex-end',
	},
	'modalBody--justify': {
		alignItems: 'unset',
	},
	buttons: {
		width: '100%',
	},
	formatting: {
		'.error': {
			color: theme.colors.light.error.default,
			fontWeight: 'bold',
		},
	},
}));