import React from 'react';
import PropTypes from 'prop-types';

import {Group, Modal, Progress, Stack} from '@mantine/core';
import Text from 'components/core/Text';

// Local styles
import {useStyles} from './styles';
import FaDisplay from 'components/others/FaDisplay';
import {faSync} from '@fortawesome/pro-solid-svg-icons';
import {AnimatePresence, motion} from 'framer-motion';

const SyncOverlay = ({visible, progressBarPercent, items, titleAction}) => {
	const {classes, theme} = useStyles();

	const getCurrentItem = (progress = 0) => {
		const totalItems = items.length;
		const currentStep = Math.ceil((progress / 100) * totalItems);

		if (currentStep <= 0) {
			return items[0];
		} else if (currentStep >= totalItems) {
			return items[totalItems - 1];
		} else {
			return items[currentStep - 1];
		}
	};

	const currentItem = getCurrentItem(progressBarPercent);

	return (
		<Modal
			size={544}
			padding={24}
			withCloseButton={false}
			centered
			onClose={() => null}
			opened={visible}>
			<Stack spacing={16}>
				{/* Info Section */}
				<Group spacing={16}>
					<FaDisplay
						faProps={{spin: true}}
						containerSize={48}
						faIcon={faSync}
						fontSize={24}
						color={theme.colors.light.info.default}
						className={classes.iconContainer}
					/>
					<Stack
						spacing={4}
						style={{flex: 1}}>
						<Group
							spacing={4}
							style={{position: 'relative', flex: 1}}>
							<Text
								typography='title-bold-18'>
								{titleAction}
							</Text>
							<AnimatePresence>
								<motion.div
									key={currentItem?.key}
									initial={{y: 20, opacity: 0}}
									animate={{y: 0, opacity: 1}}
									exit={{y: -20, opacity: 0}}
									transition={{ease: 'easeInOut'}}
									style={{position: 'absolute', left: 76}}
								>
									<Text
										typography='title-bold-18'>
										{currentItem?.label}
									</Text>
								</motion.div>
							</AnimatePresence>
						</Group>
						<Text
							typography='body-regular-14'
							color={theme.colors.light.text.secondary}>{currentItem?.desc}
						</Text>
					</Stack>
				</Group>

				{/* Progress Section */}
				<Group
					position='apart'
					spacing={12}
				>
					<Progress
						className={classes.progressBar}
						value={progressBarPercent} />
					<Text typography='subtitle-medium-14'>{`${progressBarPercent}%`}</Text>
				</Group>
			</Stack>
		</Modal>
	);
};

SyncOverlay.defaultProps = {
	visible: false,
	progressBarPercent: 0,
	items: [],
	titleAction: null,
};

SyncOverlay.propTypes = {
	visible: PropTypes.bool,
	progressBarPercent: PropTypes.number,
	items: PropTypes.array,
	titleAction: PropTypes.string,
};

export default SyncOverlay;