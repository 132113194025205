import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';

import StartDay from 'views/Shift/StartDay';
import EndDay from 'views/Shift/EndDay';

import {shouldShowEndDayReminder} from 'utils/pos';

const StartSessionBlocker = ({children}) => {
	const user = useSelector(({reducerUser}) => reducerUser) || {};
	let location = useLocation();

	const isAdmin = user?.userType == 'admin';
	const restrictedRoute = ['/', '/pos', '/menu'].includes(location.pathname);
	const endDayRestrictedRoute = [
		...(location?.state?.bypassInput ? [''] : ['/pos']),
		'/menu',
	].includes(location.pathname);
	const condition = isAdmin || (!isEmpty(user.session) && !user.session.isFinished);

	// Render
	if (restrictedRoute && !condition) return <StartDay />;
	if (endDayRestrictedRoute && shouldShowEndDayReminder()) return <EndDay />;
	else return children;
};

StartSessionBlocker.propTypes = {
	children: PropTypes.node,
};

export default StartSessionBlocker;
