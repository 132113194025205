// Local Redux
import {POS_EVENTS} from 'utils/constants';
import {
	SET_MENU_TEMPLATE,
	SET_CART,
	CLEAR_POS_DATA,
	ADD_CART_ITEM,
	REMOVE_CART_ITEM,
	UPDATE_CART_ITEM,
	CLEAR_CART_ITEM,
	SET_VISIT_PURPOSE,
	SET_ORDER_ORDER_ID,
	SET_ORDER_CUSTOMER_NAME,
	SET_ORDER_VOUCHERS,
	SET_PAYMENT_LIST,
	SET_CASH_AMOUNT,
	CLEAR_ORDER_DETAILS,
	SET_PAYMENT,
	CLEAR_PAYMENT,
	SET_SALES_TYPE,
	SET_LOADING_PUNCHIN,
	SET_FREE_ITEMS,
	CLEAR_FREE_ITEMS,
	SET_ADDITIONAL_INFO,
	CLEAR_ADDITIONAL_INFO,
	INITIATE_POS_EVENT,
	ADD_POS_EVENT,
} from '../actionTypes';

const initialOrderDetails = {
	orderId: null,
	customerName: null,
	vouchers: [],
	cashAmount: 0,
	salesType: null,
};

const initialState = {
	menuTemplate: {},
	visitPurpose: {
		platform: null,
		brand: null,
		salesType: null,
		salesChannel: null,
	},
	orderDetails: initialOrderDetails,
	paymentList: [],
	cart: [],
	payment: {},
	loadingPunchIn: false,
	freeItems: [],
	additionalInfo: null,
	events: [],
};

const reducerPos = (state = initialState, {type, data}) => {
	switch (type) {
		case SET_MENU_TEMPLATE:
			return {...state, menuTemplate: data.menuTemplate};
		case SET_PAYMENT_LIST:
			return {...state, paymentList: data.paymentList};
		case SET_PAYMENT:
			return {...state, payment: data.payment};
		case CLEAR_PAYMENT:
			return {...state, payment: {}};
		case SET_CART:
			return {...state, cart: data.cart};
		case SET_LOADING_PUNCHIN:
			return {...state, loadingPunchIn: data.loadingPunchIn};
		case SET_VISIT_PURPOSE:
			return {...state, visitPurpose: data.visitPurpose};
		case ADD_CART_ITEM:
			return {...state, cart: state.cart.concat(data.cartItem)};
		case REMOVE_CART_ITEM:
			return {...state, cart: state.cart.filter((_, index) => index !== data.indexToRemove)};
		case CLEAR_CART_ITEM:
			return {...state, cart: []};
		case CLEAR_ORDER_DETAILS:
			return {...state, orderDetails: initialOrderDetails};
		case SET_ORDER_ORDER_ID: {
			const updatedorderDetails = {...state.orderDetails};
			updatedorderDetails.orderId = data.orderId;
			return {...state, orderDetails: updatedorderDetails};
		}
		case SET_ORDER_CUSTOMER_NAME: {
			const updatedorderDetails = {...state.orderDetails};
			updatedorderDetails.customerName = data.customerName;
			return {...state, orderDetails: updatedorderDetails};
		}
		case SET_ORDER_VOUCHERS: {
			const updatedorderDetails = {...state.orderDetails};
			updatedorderDetails.vouchers = data.vouchers;
			return {...state, orderDetails: updatedorderDetails};
		}
		case SET_CASH_AMOUNT: {
			const updatedorderDetails = {...state.orderDetails};
			updatedorderDetails.cashAmount = data.cashAmount;
			return {...state, orderDetails: updatedorderDetails};
		}
		case SET_SALES_TYPE: {
			const updatedorderDetails = {...state.orderDetails};
			updatedorderDetails.salesType = data.salesType;
			return {...state, orderDetails: updatedorderDetails};
		}
		case UPDATE_CART_ITEM: {
			const updatedCartItems = [...state.cart];
			updatedCartItems[data.indexToUpdate] = data.cartItem;
			return {
				...state,
				cart: updatedCartItems,
			};
		}
		case SET_FREE_ITEMS: {
			return {...state, freeItems: data.freeItems};
		}
		case CLEAR_FREE_ITEMS: {
			return {...state, freeItems: []};
		}
		case SET_ADDITIONAL_INFO: {
			return {...state, additionalInfo: data.additionalInfo};
		}
		case CLEAR_ADDITIONAL_INFO: {
			return {...state, additionalInfo: null};
		}
		case INITIATE_POS_EVENT: {
			return {
				...state, events: [{eventName: POS_EVENTS.SET_SALES_CHANNEL, ts: new Date()}],
			};
		}
		case ADD_POS_EVENT: {
			return {
				...state, events: state.events.concat(data.event),
			};
		}
		case CLEAR_POS_DATA:
			return initialState;
		default:
			return state;
	}
};

export default reducerPos;