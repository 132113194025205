import {createStyles} from '@mantine/core';

export const useStyles = createStyles((theme, {
	height,
	width,
	paddingHorizontal,
	paddingVertical,
	bgColor,
	borderColor,
	disabled,
}) => ({
	container: {
		boxSizing: 'border-box',
		WebkitBoxSizing: 'border-box',
		MozBoxSizing: 'border-box',
		height,
		width,

		backgroundColor: `${bgColor || theme.colors.light.neutral.primary}`,
		boxShadow: theme.shadows.xs,

		borderRadius: '8px',
		border: `1px solid ${borderColor || theme.colors.light.neutral.border}`, // Using Outlet instead of border to prevent additional width/height

		padding: `${paddingVertical}px ${paddingHorizontal}px`,
		cursor: `${disabled ? 'not-allowed' : 'pointer'}`,

		// transitionProperty: 'background-color',
		// transitionDuration: '300ms',

		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& *': {
			cursor: `${disabled ? 'not-allowed' : 'pointer'}`,
		},
	},
}));