import {createStyles} from '@mantine/core';

export const useStyles = createStyles(theme => ({
	wrapper: {

	},
	buttonContainer: {
		cursor: 'pointer',
		padding: '4px 12px',
		borderRadius: '8px',
	},
	buttonContainerActive: {
		backgroundColor: theme.colors.light.primary.background,
		color: theme.colors.light.primary.default,
	},
}));