import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {Box, Stack} from '@mantine/core';

// Global components
import Card from 'components/core/Card';
import FaDisplay from 'components/others/FaDisplay';
import Text from 'components/core/Text';
import Button from 'components/core/Button';

// Local styles
import {useStyles} from './styles';

const DayCard = ({faIcon, iconColor, iconBgColor, title, subtitle, actionBtnLabel, onClickActionBtn, btnDisabled, children}) => {
	const {classes, theme} = useStyles({iconBgColor});
	const allowPos = useSelector(({reducerElectron}) => reducerElectron.allowPos);

	return (
		<Card
			className={classes.card}
		>
			<Stack
				justify='center'
				align='center'>
				<Box
					className={classes.iconContainer}
					spacing={16}>
					<FaDisplay
						fontSize={24}
						containerSize={48}
						color={iconColor}
						faIcon={faIcon}
					/>
				</Box>

				<Stack spacing={4}>
					<Text
						align='center'
						typography='title-bold-18'>{title}</Text>
					<Text
						color={theme.colors.light.text.secondary}
						align='center'
						typography='body-regular-14'>{subtitle}</Text>
				</Stack>

				{children}

				{allowPos && (
					<Button
						disabled={btnDisabled}
						className={classes.btn}
						mt={8}
						onClick={onClickActionBtn}>
						{actionBtnLabel}
					</Button>
				)}
			</Stack>

		</Card>
	);
};

DayCard.defaultProps = {
	iconColor: null,
	iconBgColor: () => null,
	title: '',
	subtitle: '',
	actionBtnLabel: '',
	onClickActionBtn: () => null,
	btnDisabled: false,
};

DayCard.propTypes = {
	faIcon: PropTypes.object,
	iconColor: PropTypes.string,
	iconBgColor: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	children: PropTypes.node,
	actionBtnLabel: PropTypes.string,
	onClickActionBtn: PropTypes.func,
	btnDisabled: PropTypes.bool,
};

export default DayCard;