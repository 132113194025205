import {io} from 'socket.io-client';
import {isInElectron} from 'utils';

export const socket = io(process.env.REACT_APP_ORDERHUB_SOCKET_DOMAIN, {
// export const socket = io('https://api-dev.ishangry.com', {
	autoConnect: true,
	path: `${isInElectron ? '' : '/pos'}/socket.io`,
	transports: ['websocket'],
	auth: {token: 'abcde'},
	reconnectionDelay: 5000,
});