import {useEffect, useState} from 'react';
import Speech from 'speak-tts';

const useSpeech = () => {
	const [speech] = useState(() => new Speech());

	useEffect(() => {
		if (speech.browserSupport) {
			speech.init({
				volume: 1,
				lang: 'id-ID',
				rate: 0.8,
				pitch: 1,
				voice: 'Google Bahasa Indonesia',
			});
		}
	}, [speech]);

	const speak = ({
		text,
		beforeStart = () => null,
		beforeEnd = () => null,
	}) => {
		if (speech.browserSupport) {
			speech.speak({
				text,
				queue: true,
				listeners: {
					onstart: () => {
						beforeStart();
					},
					onend: () => {
						beforeEnd();
					},
				},
			});
		}
	};

	return {speak};
};

export default useSpeech;
