import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import {faAlarmSnooze, faDoorClosed, faDoorOpen, faSmile} from '@fortawesome/pro-solid-svg-icons';

// Global components
import ConfirmationModal from 'components/others/Modal/ConfirmationModal';
import CheckboxRadioCard from 'components/others/CheckboxRadioCard';

import {Select, Stack} from '@mantine/core';

import {useStyles} from './styles';
import notification from 'utils/notification';

// Request
import {snoozeOnOffReminder, toggleMerchantPerLocation} from 'request/outletOnOff';

const REMINDER_OPTION_KEY = {
	handleAllMerchants: 'handleAllMerchants',
	remindLater: 'remindLater',
};

const OutletStatusReminder = ({open, close, isOverload, isAllowDoNothing}) => {
	const {t} = useTranslation('translation', {keyPrefix: 'global.header.merchantsStatus.Reminder'});
	const {t: overloadT} = useTranslation('translation', {keyPrefix: 'global.header.merchantsStatus.Reminder.Overload'});
	const {t: normalT} = useTranslation('translation', {keyPrefix: 'global.header.merchantsStatus.Reminder.Normal'});
	const {classes, theme} = useStyles();

	const [selectedOption, setSelectedOption] = useState(null);
	const [snoozeTime, setSnoozeTime] = useState(null);
	const [loading, setLoading] = useState(false);

	const tState = isOverload && !isAllowDoNothing ? overloadT : normalT;

	const FLAG_TYPES = [{
		id: REMINDER_OPTION_KEY.handleAllMerchants,
		icon: isOverload ? faDoorClosed : faDoorOpen,
		iconColor: isOverload ? theme.colors.light.info.default : theme.colors.light.success.default,
		title: tState('handleAllMerchants.title'),
		subtitle: tState('handleAllMerchants.subtitle'),
		selected: selectedOption == REMINDER_OPTION_KEY.handleAllMerchants,
	},
	{
		id: REMINDER_OPTION_KEY.remindLater,
		icon: faAlarmSnooze,
		iconColor: theme.colors.light.error.default,
		title: tState('remindLater.title'),
		subtitle: tState('remindLater.subtitle'),
		selected: selectedOption == REMINDER_OPTION_KEY.remindLater,
		extra: selectedOption == REMINDER_OPTION_KEY.remindLater && (
			<Select
				className={classes.select}
				placeholder={tState('remindLater.placeholder')}
				data={[10,20,30].map(snoozeDuration => {
					return {value: snoozeDuration, label: dayjs().to(dayjs().add(snoozeDuration, 'minute'), true)};
				})}
				onChange={val => setSnoozeTime(val)}
			/>
		),
	}];

	const onOk = async () => {
		try {
			setLoading(true);
			if (selectedOption == REMINDER_OPTION_KEY.remindLater) {
				const response = await snoozeOnOffReminder({
					type: isOverload
						? 'turn_off_merchant_modal'
						: 'turn_on_merchant_modal',
					duration: snoozeTime,
				});
				if (response.success) {
					notification.normal({
						title: tState('reminderLaterNotification.title'),
						message: tState('reminderLaterNotification.message', {
							minutes: snoozeTime,
						}),
					});
					close();
				}
			} else {
				const response = await toggleMerchantPerLocation(isOverload ? false : true);
				if (response.success) {
					close();
				}
			}
		} catch (error) {

		} finally {
			setLoading(false);
		}
	};

	const clearState = () => {
		setSelectedOption(null);
	};

	useEffect(() => {
		clearState();
	}, [open]);

	useEffect(() => {
		setSnoozeTime(null);
	}, [selectedOption]);

	return (
		<ConfirmationModal
			opened={open}
			loading={loading}
			state={isOverload && !isAllowDoNothing
				? 'warning'
				: 'success'
			}
			faIcon={!isOverload || isAllowDoNothing && faSmile}
			title={tState('title')}
			subtitle={isAllowDoNothing ? tState('subtitleDoNothing') : tState('subtitle')}
			singleButtonMode
			confirmLabel={isAllowDoNothing ? t('ok') : t('continue')}
			primaryButtonProps={{
				disabled: !isAllowDoNothing &&
				!selectedOption ||
        (selectedOption == REMINDER_OPTION_KEY.remindLater && !snoozeTime),
			}}
			onClickCancel={close}
			onClickConfirm={onOk}>
			{
				!isAllowDoNothing && (
					<Stack
						spacing={8}
						className={classes.cards}>
						{FLAG_TYPES.map(({id, icon, iconColor, title, subtitle, selected, disabled, extra}) => (
							<CheckboxRadioCard
								key={id}
								selected={selected}
								faIcon={icon}
								faIconColor={iconColor}
								title={title}
								subtitle={subtitle}
								disabled={disabled}
								onClick={() => !loading && setSelectedOption(id)}
							>
								{extra}
							</CheckboxRadioCard>
						))}
					</Stack>
				)
			}
		</ConfirmationModal>
	);
};

OutletStatusReminder.defaultProps = {
	open: false,
	close: () => null,
	isOverload: false,
	isAllowDoNothing: false,
};

OutletStatusReminder.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	isOverload: PropTypes.bool,
	isAllowDoNothing: PropTypes.bool,
};

export default OutletStatusReminder;