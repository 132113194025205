import {Box, Card as MantineCard, Group} from '@mantine/core';
import PropTypes from 'prop-types';

// Global components
import Text from 'components/core/Text';

// Local styles
import {useStyles} from './styles';

const Card = ({flex, contentPadding, children, header, footer, shadow, className, contentClassName, ...restProps}) => {
	const {classes, cx, theme} = useStyles();

	// Render
	return (
		<MantineCard
			p={0}
			shadow={typeof shadow === 'string' // check if shadow is a string, if yes apply that shadow
				? shadow
				: shadow // else when it's boolean, apply the default shadow if true, else don't apply any shadow
					? theme.shadows.esopqu
					: undefined
			}
			className={cx(classes.card, {[classes.cardGrow]: flex}, className)}
			{...restProps}>
			{header && (
				<MantineCard.Section className={classes.header}>
					{header?.title ? (
						<Group
							px={24}
							py={20}
							spacing={8}>
							<Box mr="auto">
								<Text
									size="lg"
									weight={500}>
									{header.title}
								</Text>
								<Text
									size="sm"
									color={theme.colors.light.text.secondary}>
									{header.subtitle}
								</Text>
							</Box>
							{header.rightRender}
						</Group>
					) : header}
				</MantineCard.Section>
			)}
			<MantineCard.Section
				p={contentPadding}
				className={cx(classes.content, contentClassName)}>
				{children}
			</MantineCard.Section>
			{footer && (
				<MantineCard.Section className={classes.footer}>
					{footer}
				</MantineCard.Section>
			)}
		</MantineCard>
	);
};

Card.defaultProps = {
	contentPadding: 24,
	shadow: true,
};

Card.propTypes = {
	flex: PropTypes.bool,
	contentPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	children: PropTypes.node,
	header: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
	footer: PropTypes.node,
	shadow: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	className: PropTypes.string,
	contentClassName: PropTypes.string,
};

export default Card;
