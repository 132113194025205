import {setElectronData} from 'store/actions';
import {store} from 'store';

export const initializeElectronData = () => {
	window.api?.send?.('frestoReady');
	window.api?.receive?.('frestoReady', data => {
		store.dispatch(setElectronData(data));
	});
};

// Receives either auth token or false (not logged in)
export const sendAuthTokenToElectron = token => {
	window.api?.send?.('frestoAuthToken', {
		token,
	});
};