// Global utils
import AuthUtils from 'utils/auth';

import {store} from 'store';

// Request utils
import request, {REQUEST_ENV} from 'request';
import {setTurnOffReasons} from 'store/actions';

export const getTurnOffReasonsRequest = async setState => {
	try {
		const locationId = store.getState().reducerUser?.selectedLocation?.id;
		const {data} = await request(
			{
				method: 'get',
				url: `${REQUEST_ENV.ORDERHUB_API_HOST}/menu-availability/reason`,
				headers: {
					Authorization: `Bearer ${AuthUtils.sessionToken}`,
				},
				params: {locationId},
			},
			setState,
		);
		store.dispatch(setTurnOffReasons(data));
	} catch {}
};

export const getOrderListRequest = async (payload, setState) => {
	// payload: {orderDate, locationId, status, limit, offset, sortBy, sortOrder, sessionId?}
	return request(
		{
			method: 'get',
			url: `${REQUEST_ENV.POS_API_HOST}/order/list`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			params: {
				...payload,
			},
		},
		setState,
	);
};

export const getReconSummaryRequest = async (payload, setState) => {
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	// payload: {locationId, startTime, endTime}
	return request(
		{
			method: 'get',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/order/summary`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			params: {
				locationId,
				...payload,
			},
			timeout: 60000,
		},
		setState,
	);
};
export const getReconDetailsRequest = async (payload, setState) => {
	const locationId = store.getState().reducerUser?.selectedLocation?.id;
	// payload: {locationId, startTime, endTime, brandId, platformId}
	return request(
		{
			method: 'get',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/order/summary/list`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			params: {
				locationId,
				limit: 999999,
				...payload,
			},
			timeout: 60000,
		},
		setState,
	);
};

// export const processOrderRequest = async (payload, setState) => {
// 	// payload: {orderId, processedBy}
// 	return request(
// 		{
// 			method: 'put',
// 			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/order/process`,
// 			headers: {
// 				Authorization: `Bearer ${AuthUtils.sessionToken}`,
// 			},
// 			data: payload,
// 		},
// 		setState,
// 	);
// };

export const processOrderRequest = async (payload, setState) => {
	// payload: {orderId, auto}
	/**
	 * auto: true -> auto process
	 * auto: false -> manual process, pair later
	 */
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.POS_API_HOST}/order/process`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

export const markDone = async (payload, setState) => {
	// payload: {orderId}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.POS_API_HOST}/order/done`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

export const manualPairOrder = async (payload, setState) => {
	// payload: {orderId, pairOrderId, pairReason}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.POS_API_HOST}/order/pair/manual`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

export const pseudoPairOrder = async (payload, setState) => {
	// payload: {orderId, merchantRefId}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.POS_API_HOST}/order/pair/force`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

export const editOrderBookingId = async (payload, setState) => {
	// payload: {orderId, merchantRefId}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.POS_API_HOST}/order/booking`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

export const voidOrder = async (payload, setState) => {
	// payload: {orderId, pinNumber, voidReason}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.POS_API_HOST}/order/void`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

export const flagCancelOrderRequest = async (payload, setState) => {
	// payload: {orderId, reason}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/mark/cancel`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

export const flagUpdateOrderRequest = async (payload, setState) => {
	// payload: {orderId}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/order/update`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

export const markFraud = async (payload, setState) => {
	const {externalId} = payload;
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/order/mark/fraudulent/${externalId}`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
		},
		setState,
	);
};

// Hangry App APIs
export const cancelHangryAppOrderRequest = async (payload, setState) => {
	// payload: {orderId, pinNumber, voidReason}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.POS_API_HOST}/order/hangry/cancel`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

// export const cancelHangryAppOrderRequest = async (payload, setState) => {
// 	// payload: {orderId, reason}
// 	return request(
// 		{
// 			method: 'put',
// 			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/order/hangry/cancel`,
// 			headers: {
// 				Authorization: `Bearer ${AuthUtils.sessionToken}`,
// 			},
// 			data: payload,
// 		},
// 		setState,
// 	);
// };

export const findDriverHangryAppOrderRequest = async (payload, setState) => {
	// payload: {orderId}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/order/hangry/find-driver`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

export const changeDriverHangryAppOrderRequest = async (payload, setState) => {
	// payload: {orderId}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/order/hangry/change-driver`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

export const markPickupReadyHangryAppOrderRequest = async (payload, setState) => {
	// payload: {orderId}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.ORDERHUB_API_HOST}/order/hangry/process/ready`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
		setState,
	);
};

export const autoInjectOrder = async ({orderId}, setState) => {
	// payload: {orderId}
	return request(
		{
			method: 'put',
			// url: `${REQUEST_ENV.ORDERHUB_API_HOST}/resto/order/inject`,
			url: `${REQUEST_ENV.POS_API_HOST}/order/process`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: {orderId, auto: true},
		},
		setState,
	);
};

export const markRTPU = async ({orderId}, setState) => {
	// payload: {orderId}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.POS_API_HOST}/order/ready`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: {orderId},
		},
		setState,
	);
};

export const triggerRecall = async ({orderCode}) => {
	// payload: {orderCode}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.POS_API_HOST}/order/announce`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: {orderCode},
		},
	);
};

export const toggleRatingBoost = async payload => {
	// payload: {orderId, isRatingBoost}
	return request(
		{
			method: 'put',
			url: `${REQUEST_ENV.POS_API_HOST}/order/rating-boost`,
			headers: {
				Authorization: `Bearer ${AuthUtils.sessionToken}`,
			},
			data: payload,
		},
	);
};