import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {setSession} from 'store/actions';

import {Group, Stack, TextInput} from '@mantine/core';
import get from 'lodash/get';

// Fontawesome
import FaDisplay from 'components/others/FaDisplay';
import {faPlay, faUser} from '@fortawesome/pro-solid-svg-icons';

// Global components
import Text from 'components/core/Text';
import ConfirmationModal from 'components/others/Modal/ConfirmationModal';
import POSButton from 'views/POS/components/POSButton';

import generateSyncOptions from 'components/others/SyncHandler/generateSyncOptions';

// Local components
import DayCard from '../components/DayCard';

// Local styles
import {useStyles} from './styles';

// Request
import {startDay} from 'request/session';

// Utils
import {idrPrefix} from 'utils';
import notification from 'utils/notification';

const STARTING_CASH_OPTIONS = [0, 500000];

const StartDay = ({withoutTopBorder}) => {
	const dispatch = useDispatch();
	const {classes, theme} = useStyles({
		withoutTopBorder,
	});
	const {selectedLocation, username} = useSelector(({reducerUser}) => reducerUser) || {};
	const allowPos = useSelector(({reducerElectron}) => reducerElectron.allowPos);

	const {t} = useTranslation('translation', {keyPrefix: 'pages.shift.start'});

	const [startingBalance, setStartingBalance] = useState(null);
	const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const loadingRef = useRef(null);

	const startAutoSync = async () => {
		const syncOptions = generateSyncOptions();
		const syncKeys = Object.values(syncOptions).map(syncItem => syncItem.key);

		window?.startSync?.({
			selectedOptions: syncKeys,
			titleAction: t('syncing'),
		});
	};

	const confirmStartShift = async () => {
		try {
			if (loadingRef.current) return;
			loadingRef.current = true;
			setLoading(true);

			const response = await startDay({cashStart: startingBalance});
			if (response.success) {
				dispatch(setSession({session: response.data.session}));
				window?.refreshOrders?.();

				await startAutoSync();

				notification.success({
					title: t('success.title'),
					message: t('success.message'),
				});
			}
		} catch (error) {
			const errHttpStatus = get(error, ['response', 'status']);
			if (errHttpStatus === 422) window.location.reload();
		} finally {
			loadingRef.current = false;
			setLoading(false);
			setStartingBalance(null);
		}
	};

	return (
		<Stack className={classes.container}>
			<ConfirmationModal
				state='warning'
				opened={confirmationModalOpen}
				title={t('confirmation.title')}
				subtitle={t('confirmation.subtitle')}
				confirmLabel={t('confirmation.ok')}
				cancelLabel={t('confirmation.cancel')}
				onClickConfirm={confirmStartShift}
				onClickCancel={() => setConfirmationModalOpen(false)}
				onClose={() => setConfirmationModalOpen(false)}
				loading={loading}
				secondaryButtonProps={{disabled: loading}}
			/>
			<DayCard
				faIcon={faPlay}
				iconColor={theme.colors.light.info.default}
				iconBgColor={theme.colors.light.info.background}
				title={t('title')}
				subtitle={allowPos ? t('subtitle', {
					outletName: selectedLocation?.label,
				}) : t('nonPosSubtitle')}
				actionBtnLabel={t('action')}
				btnDisabled={startingBalance === null}
				onClickActionBtn={() => setConfirmationModalOpen(true)}
			>
				{
					allowPos && (
						<Stack
							className={classes.inputContainer}
							spacing={12}>

							{/* User */}
							<Stack spacing={4}>
								<Text
									color={theme.colors.light.text.secondary}
									typography='subtitle-medium-14'>{t('cashierLabel')}</Text>
								<TextInput
									disabled
									value={username}
									icon={(
										<FaDisplay
											color={theme.colors.light.text.disabled}
											faIcon={faUser}
											containerSize={24}
											fontSize={16}
										/>
									)}
								/>
							</Stack>

							{/* Balance */}
							<Stack spacing={4}>
								<Text
									color={theme.colors.light.text.secondary}
									typography='subtitle-medium-14'>{t('startingCashLabel')}</Text>
								<Group spacing={8}>
									{
										STARTING_CASH_OPTIONS.map(cash => (
											<POSButton
												onClick={() => setStartingBalance(cash)}
												bgColor={startingBalance === cash ? theme.colors.light.primary.background : null}
												borderColor={startingBalance === cash ? theme.colors.light.primary.border : null}
												className={classes.buttonContainer}
												paddingVertical={18}
												paddingHorizontal={12}
												key={cash}>
												<Text
													color={startingBalance === cash ? theme.colors.light.primary.default : theme.colors.light.text.primary}
													typography='title-bold-14'>
													{idrPrefix(cash)}
												</Text>
											</POSButton>
										))
									}
								</Group>
							</Stack>

						</Stack>
					)
				}
			</DayCard>
		</Stack>
	);
};

StartDay.defaultProps = {
	withoutTopBorder: false,
};

StartDay.propTypes = {
	withoutTopBorder: PropTypes.bool,
};

export default StartDay;