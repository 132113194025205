import {createStyles} from '@mantine/core';

export const useStyles = createStyles(theme => ({
	container: {
		paddingBlock: '7px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		color: theme.colors.light.neutral.primary,
		backgroundColor: theme.colors.light.success.default,
	},
	['container--danger']: {
		backgroundColor: theme.colors.light.error.default,
	},
}));