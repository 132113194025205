import {useEffect} from 'react';
import {Anchor, Box, Group, Image} from '@mantine/core';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {clearOrderDrawerData} from 'store/actions';

// Fontawesome
import {faBookArrowRight, faCashRegister, faChartLine, faUtensils} from '@fortawesome/pro-solid-svg-icons';

// Global assets
import SVGLogo from 'assets/logos/Fresto.svg';

// Global components
import SyncHandler from '../SyncHandler';

// Local components
import OnOffMenuNotification from './OnOffMenuNotification';
import ServiceStatus from './ServiceStatus';
import OutletStatus from './OutletStatus';
import NavMenu from './NavMenu';
import NavUser from './NavUser';

// Local styles
import {useStyles} from './styles';
import dayjs from 'dayjs';

const Header = () => {
	const {t} = useTranslation('translation', {keyPrefix: 'global.header'});
	const allowPos = useSelector(({reducerElectron}) => reducerElectron.allowPos);
	const {open} = useSelector(({reducerOrder}) => reducerOrder.drawer);
	const {classes} = useStyles();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const goToRecon = () => {
		const initialDay = dayjs().startOf('day').subtract(dayjs().hour() < 7 ? 1 : 0, 'day').set('hour', 7);
		const dateRange = [
			initialDay.toDate(),
			initialDay.add(1, 'day').subtract(1, 'hour').toDate(),
		];
		sessionStorage.setItem('lastPickedRange', `${dateRange[0].toISOString()}@${dateRange[1].toISOString()}`);
		navigate('/recon');
	};

	const navigationHandler = (navigationFn = () => null) => {
		// Clear drawer from edit payment if exit
		if (open) dispatch(clearOrderDrawerData());
		navigationFn?.();
	};

	const frestoMenus = [
		...(allowPos ? [
			{icon: faUtensils, label: t('order'), onClick: () => navigationHandler(navigate('/')), pathname: '/', spin: false},
			{icon: faCashRegister, label: t('pos'), onClick: () => navigationHandler(navigate('/pos')), pathname: '/pos'},
		] : []),
		{icon: faBookArrowRight, label: t('manageMenu'), onClick: () => navigationHandler(navigate('/menu')), pathname: '/menu'},
		...(allowPos ? [
			{icon: faChartLine, label: t('reconciliation'), onClick: () => navigationHandler(goToRecon()), pathname: '/recon'},
		] : []),
	];

	useEffect(() => {
		if (location.pathname === '/' && !allowPos) return navigate('/menu', {replace: true});
	}, [allowPos, location.pathname]);

	// Render
	return (
		<>
			<SyncHandler />
			<ServiceStatus />
			<OnOffMenuNotification />
			<Group
				className={classes.header}
				spacing={16}>
				<Group
					noWrap
					className={classes.column}
					spacing={8}>
					<Anchor
						component={Link}
						to="/">
						<Image
							src={SVGLogo}
							width={76}
							height={32}
							fit="contain" />
					</Anchor>
					<OutletStatus />
				</Group>
				<Box className={classes.column}>
					<NavMenu availableMenus={frestoMenus} />
				</Box>
				<Group className={classes.column}>
					<NavUser />
				</Group>
			</Group>
		</>
	);
};

export default Header;
