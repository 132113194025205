import {createStyles} from '@mantine/core';

export const useStyles = createStyles((theme, _, getRef) => ({
	checkboxRadioContainer: {
		cursor: 'pointer',
		width: '100%',
		boxSizing: 'border-box',
		borderRadius: 8,
		border: `1px solid ${theme.colors.light.neutral.border}`,
		transitionDuration: '250ms',
		alignItems: 'flex-start',
	},
	checkboxRadioContainerActive: {
		border: `1px solid ${theme.colors.light.primary.default}`,
	},
	checkboxRadioContainerDisabled: {
		border: `1px solid ${theme.colors.light.neutral.border}`,
		backgroundColor: theme.colors.light.neutral.base,
		[`.${getRef('icon')}`]: {
			svg: {
				color: theme.colors.light.text.disabled,
			},
		},
		[`.${getRef('checkbox')}`]: {
			backgroundColor: theme.colors.light.neutral.secondary,
			borderColor: theme.colors.light.neutral.border,
			'&:checked': {
				backgroundColor: theme.colors.light.text.disabled,
				borderColor: 'transparent',
			},
		},
	},
	checkboxRadioInnerContainer: {
		width: '100%',
	},
	icon: {
		ref: getRef('icon'),
	},
	checkboxRadioText: {
		flex: 1,
	},
	checkboxRadioInput: {
		ref: getRef('checkbox'),
		pointerEvents: 'none',
		'&:checked': {
			backgroundColor: theme.colors.light.primary.default,
			borderColor: theme.colors.light.primary.default,
		},
	},
	checkboxRadioInputRounded: {
		borderRadius: '50%',
	},
	checkboxRadioIcon: {
		color: `${theme.colors.light.neutral.primary} !important`,
	},
}));