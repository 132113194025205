import React from 'react';
import PropTypes from 'prop-types';

import {Box} from '@mantine/core';

// Local Styles
import {useStyles} from './styles';

const POSButton = ({onClick, disabled,
	height, width,
	paddingHorizontal, paddingVertical,
	bgColor, borderColor, children,
	className}) => {
	const {classes, cx} = useStyles({
		height,
		width,
		paddingHorizontal,
		paddingVertical,
		bgColor: bgColor,
		borderColor: borderColor,
		disabled,
	});

	return (
		<Box
			onClick={() => !disabled && onClick()}
			className={cx(classes.container, className)}>
			{children}
		</Box>
	);
};

POSButton.defaultProps = {
	onClick: () => null,
	height: 'unset',
	width: 'unset',
	paddingHorizontal: 12,
	paddingVertical: 8,
	bgColor: null,
	borderColor: null,
	disabled: false,
};

POSButton.propTypes = {
	onClick: PropTypes.func,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	children: PropTypes.node,
	paddingHorizontal: PropTypes.number,
	paddingVertical: PropTypes.number,
	bgColor: PropTypes.string,
	borderColor: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
};

export default POSButton;