import {
	SET_USER_DATA,
	CLEAR_USER_DATA,
	SET_IS_SERVER_ONLINE,
	SET_ACTIVE_ORDERS,
	SET_IS_FETCHING_ACTIVE_ORDER,
	SET_HISTORY_DATE,
	SET_HISTORY_ORDERS,
	SET_IS_FETCHING_HISTORY_ORDER,
	SET_ORDER_DRAWER_DATA,
	CLEAR_ORDER_DRAWER_DATA,
	SET_PLATFORMS,
	SET_BRANDS,
	SET_REMOTE_CONFIG,
	SET_TURN_OFF_REASONS,
	SET_OUTLET_ON_OFF_REASONS_DURATION,
	SET_ELECTRON_DATA,
	CLEAR_ELECTRON_DATA,
	SET_MENU_TEMPLATE,
	SET_CART,
	CLEAR_POS_DATA,
	ADD_CART_ITEM,
	REMOVE_CART_ITEM,
	UPDATE_CART_ITEM,
	SET_VISIT_PURPOSE,
	CLEAR_CART_ITEM,
	SET_ORDER_ORDER_ID,
	SET_ORDER_CUSTOMER_NAME,
	SET_ORDER_VOUCHERS,
	SET_PAYMENT_LIST,
	SET_CASH_AMOUNT,
	CLEAR_ORDER_DETAILS,
	SET_PAYMENT,
	CLEAR_PAYMENT,
	SET_SALES_TYPE,
	SET_SESSION,
	SET_LOADING_PUNCHIN,
	SET_SYNCING_SAPU_SAPU,
	SET_CONNECTED_TO_SERVER,
	SET_FREE_ITEMS,
	CLEAR_FREE_ITEMS,
	SET_AUTO_SYNC_RUNNING,
	SET_QUICK_SYNC_RUNNING,
	SET_DEEP_SYNC_RUNNING,
	SET_MANUAL_SYNC_RUNNING,
	SET_ADDITIONAL_INFO,
	CLEAR_ADDITIONAL_INFO,
	SET_VOID_QR_MODAL,
	CLEAR_VOID_QR_MODAL,
	INITIATE_POS_EVENT,
	ADD_POS_EVENT,
} from './actionTypes';

export const setUserData = payload => {
	return {
		type: SET_USER_DATA,
		data: payload,
	};
};

export const setSession = payload => {
	return {
		type: SET_SESSION,
		data: payload,
	};
};

export const clearUserData = () => {
	return {
		type: CLEAR_USER_DATA,
	};
};

export const setIsServerOnline = payload => {
	return {
		type: SET_IS_SERVER_ONLINE,
		data: payload,
	};
};

export const setActiveOrderData = payload => {
	return {
		type: SET_ACTIVE_ORDERS,
		data: payload,
	};
};

export const setIsFetchingActiveOrder = payload => {
	return {
		type: SET_IS_FETCHING_ACTIVE_ORDER,
		data: payload,
	};
};

export const setHistoryDate = payload => {
	return {
		type: SET_HISTORY_DATE,
		data: payload,
	};
};

export const setHistoryOrderData = payload => {
	return {
		type: SET_HISTORY_ORDERS,
		data: payload,
	};
};

export const setIsFetchingHistoryOrder = payload => {
	return {
		type: SET_IS_FETCHING_HISTORY_ORDER,
		data: payload,
	};
};

export const setOrderDrawerData = payload => {
	return {
		type: SET_ORDER_DRAWER_DATA,
		data: payload,
	};
};

export const clearOrderDrawerData = () => {
	return {
		type: CLEAR_ORDER_DRAWER_DATA,
	};
};

export const setPlatforms = payload => {
	return {
		type: SET_PLATFORMS,
		data: payload,
	};
};

export const setBrands = payload => {
	return {
		type: SET_BRANDS,
		data: payload,
	};
};

export const setRemoteConfig = payload => {
	return {
		type: SET_REMOTE_CONFIG,
		data: payload,
	};
};

export const setTurnOffReasons = payload => {
	return {
		type: SET_TURN_OFF_REASONS,
		data: payload,
	};
};

export const setOnOffOutlerReasonDuration = payload => {
	return {
		type: SET_OUTLET_ON_OFF_REASONS_DURATION,
		data: payload,
	};
};

export const setElectronData = payload => {
	return {
		type: SET_ELECTRON_DATA,
		data: payload,
	};
};

export const clearElectronData = () => {
	return {
		type: CLEAR_ELECTRON_DATA,
	};
};

export const setMenuTemplate = payload => {
	return {
		type: SET_MENU_TEMPLATE,
		data: payload,
	};
};

export const setCart = payload => {
	return {
		type: SET_CART,
		data: payload,
	};
};

export const addCartItem = payload => {
	return {
		type: ADD_CART_ITEM,
		data: payload,
	};
};

export const removeCartItem = payload => {
	return {
		type: REMOVE_CART_ITEM,
		data: payload,
	};
};

export const updateCartItem = payload => {
	return {
		type: UPDATE_CART_ITEM,
		data: payload,
	};
};

export const clearCartItem = () => {
	return {
		type: CLEAR_CART_ITEM,
	};
};

export const clearPosData = () => {
	return {
		type: CLEAR_POS_DATA,
	};
};

export const setVisitPurpose = payload => {
	return {
		type: SET_VISIT_PURPOSE,
		data: payload,
	};
};

export const setOrderOrderId = payload => {
	return {
		type: SET_ORDER_ORDER_ID,
		data: payload,
	};
};

export const setOrderCustomerName = payload => {
	return {
		type: SET_ORDER_CUSTOMER_NAME,
		data: payload,
	};
};

export const setOrderVouchers = payload => {
	return {
		type: SET_ORDER_VOUCHERS,
		data: payload,
	};
};

export const setPaymentList = payload => {
	return {
		type: SET_PAYMENT_LIST,
		data: payload,
	};
};

export const setCashAmount = payload => {
	return {
		type: SET_CASH_AMOUNT,
		data: payload,
	};
};

export const clearOrderDetails = () => {
	return {
		type: CLEAR_ORDER_DETAILS,
	};
};

export const setPayment = payload => {
	return {
		type: SET_PAYMENT,
		data: payload,
	};
};

export const setSalesType = payload => {
	return {
		type: SET_SALES_TYPE,
		data: payload,
	};
};

export const clearPayment = () => {
	return {
		type: CLEAR_PAYMENT,
	};
};

export const setLoadingPunchIn = payload => {
	return {
		type: SET_LOADING_PUNCHIN,
		data: payload,
	};
};

export const setSyncingSapuSapu = payload => {
	return {
		type: SET_SYNCING_SAPU_SAPU,
		data: payload,
	};
};

export const setConnectedToUser = payload => {
	return {
		type: SET_CONNECTED_TO_SERVER,
		data: payload,
	};
};

export const setFreeItems = payload => {
	return {
		type: SET_FREE_ITEMS,
		data: payload,
	};
};

export const clearFreeItems = () => {
	return {
		type: CLEAR_FREE_ITEMS,
	};
};

export const setAdditionalInfo = payload => {
	return {
		type: SET_ADDITIONAL_INFO,
		data: payload,
	};
};

export const clearAdditionalInfo = () => {
	return {
		type: CLEAR_ADDITIONAL_INFO,
	};
};

export const setAutoSyncRunning = payload => {
	return {
		type: SET_AUTO_SYNC_RUNNING,
		data: payload,
	};
};

export const setQuickSyncRunning = payload => {
	return {
		type: SET_QUICK_SYNC_RUNNING,
		data: payload,
	};
};

export const setDeepSyncRunning = payload => {
	return {
		type: SET_DEEP_SYNC_RUNNING,
		data: payload,
	};
};

export const setManualSyncRunning = payload => {
	return {
		type: SET_MANUAL_SYNC_RUNNING,
		data: payload,
	};
};

export const setVoidQrModal = payload => {
	return {
		type: SET_VOID_QR_MODAL,
		data: payload,
	};
};

export const clearVoidQrModal = () => {
	return {
		type: CLEAR_VOID_QR_MODAL,
	};
};

export const initiatePosEvent = () => {
	return {
		type: INITIATE_POS_EVENT,
	};
};

export const addPosEvent = payload => {
	return {
		type: ADD_POS_EVENT,
		data: payload,
	};
};