import {createStyles} from '@mantine/core';

export const useStyles = createStyles(theme => ({
	container: {
		boxSizing: 'border-box',
	},
	cleanBtn: {
		/* Remove background, border, and padding */
		background: 'none',
		border: 'none',
		padding: 0,
		/* Remove default button text styles */
		font: 'inherit',
		textDecoration: 'none',
		/* Customize button appearance */
		color: '#000', /* Set desired text color */
		cursor: 'pointer', /* Show pointer cursor on hover */
		/* Add any other custom styles you want */
	},
	iconWrapperDefault: {
		borderRadius: '50%',
		backgroundColor: theme.colors.light.neutral.base,
	},
	iconWrapperSync: {
		borderRadius: '50%',
		color: theme.colors.light.info.default,
		backgroundColor: theme.colors.light.info.background,
	},
	iconWrapperDanger: {
		borderRadius: '50%',
		color: theme.colors.light.error.default,
		backgroundColor: theme.colors.light.error.background,
	},
	rightSection: {
		flex: 1,
	},
	text: {
		wordBreak: 'break-all',
	},
	statusTextOnline: {
		color: theme.colors.light.success.default,
	},
	statusTextOffline: {
		color: theme.colors.light.error.default,
	},
	menuBody: {
		width: '228px',
		borderRadius: '12px',
		padding: 0,
	},
	serviceIndicatorWrapper: {
		padding: '4px 8px',
		borderRadius: '40px',
		border: '1px solid',
		width: 'max-content',
		backgroundColor: theme.colors.light.neutral.base,
		borderColor: theme.colors.light.neutral.border,
		color: theme.colors.light.text.secondary,
	},
	syncIndicatorWrapper: {
		padding: '4px 8px',
		borderRadius: '40px',
		border: '1px solid',
		width: 'max-content',
		backgroundColor: theme.colors.light.info.background,
		borderColor: theme.colors.light.info.border,
		color: theme.colors.light.info.default,
	},
	serviceOnline: {
		backgroundColor: theme.colors.light.success.background,
		borderColor: theme.colors.light.success.border,
		color: theme.colors.light.success.default,
	},
	serviceOffline: {
		backgroundColor: theme.colors.light.error.background,
		borderColor: theme.colors.light.error.border,
		color: theme.colors.light.error.default,
	},
	itemHovered: {
		'&:hover': {
			backgroundColor: 'unset',
		},
	},
	menuItem: {
		cursor: 'pointer',
	},
}));