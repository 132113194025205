import React from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router';

import {Group} from '@mantine/core';

// Local styles
import {useStyles} from './styles';
import FaDisplay from 'components/others/FaDisplay';
import Text from 'components/core/Text';

const NavMenu = ({availableMenus}) => {
	const {classes, cx} = useStyles();
	const location = useLocation();

	return (
		<Group spacing={8}>
			{availableMenus.map((menu, index) => {
				const isActive = menu.pathname === location.pathname;
				return (
					<Group
						onClick={menu.onClick}
						className={cx(classes.buttonContainer, isActive && classes.buttonContainerActive)}
						key={index}
						spacing={4}>
						<FaDisplay
							faProps={{spin: menu.spin}}
							containerSize={18}
							fontSize={14}
							faIcon={menu.icon}
						/>
						<Text typography='title-bold-16'>{menu.label}</Text>
					</Group>
				);})}
		</Group>
	);
};

NavMenu.defaultProps = {
	availableMenus: [],
};

NavMenu.propTypes = {
	availableMenus: PropTypes.array,
};

export default NavMenu;