import {createStyles} from '@mantine/core';

export const useStyles = createStyles((theme, {iconBgColor}) => ({
	iconContainer: {
		backgroundColor: iconBgColor || theme.colors.light.info.background,
		borderRadius: '24px',
	},
	card: {
		boxSizing: 'border-box',
		width: '400px',
		display: 'flex',
	},
	btn: {
		width: '100%',
		fontSize: '16px',
		lineHeight: '24px',
	},
}));