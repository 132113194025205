import {useTranslation} from 'react-i18next';
import {Box, Group} from '@mantine/core';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {store} from 'store';

// Fontawesome
import FaDisplay from 'components/others/FaDisplay';
import {faCircleSmall, faLoader} from '@fortawesome/pro-solid-svg-icons';
import {faArrowRotateRight} from '@fortawesome/pro-regular-svg-icons';

// Global components
import Text from 'components/core/Text';
import Button from 'components/core/Button';
import ConfirmationModal from 'components/others/Modal/ConfirmationModal';
// Local components
import OutletStatusReminder from './OutletStatusReminder';

// Utils
import notification, {generalError} from 'utils/notification';
import {socket} from 'utils/socket';

// Request
import {getMerchantDetails, retryOnOffRequest} from 'request/outletOnOff';

// Local styles
import {useStyles} from './styles';

const MERCHANT_STATUS_INITIAL_STATE = {
	count: 0,
	open: 0,
	close: 0,
	pending: [],
	success: [],
	failed: [],
	retryable: [],
	cancelled: [],
};

const OutletStatus = () => {
	const navigate = useNavigate();
	const {t} = useTranslation('translation', {keyPrefix: 'global.header.merchantsStatus'});
	const locationId = store.getState().reducerUser?.selectedLocation?.id;

	const [merchantsStatus, setMerchantsStatus] = useState(MERCHANT_STATUS_INITIAL_STATE);
	const [indicatorStatus, setIndicatorStatus] = useState(t('loading'));
	const [closeNotifierVisible, setCloseNotifierVisible] = useState(false);
	const [outletStatusReminderVisible, setOutletStatusReminderVisible] = useState(false);
	const [outletIsOverload, setOutletIsOverload] = useState(null);
	const [isAllowDoNothing, setIsAllowDoNothing] = useState(false);

	const loadingIndicator = [t('somePending'), t('loading')].some(text => text === indicatorStatus);
	const allOpen = indicatorStatus === t('allOpen');

	const {classes, cx} = useStyles();

	const fetchMerchantDetail = async () => {
		try {
			const response = await getMerchantDetails();
			if (response.success) {
				merchantsSocketHandler(response.data.merchantDetails || []);
			}
		} catch (error) {

		}
	};

	const handleRetry = async merchantIds => {
		try {
			await retryOnOffRequest({merchantIds});
			notification.hide('errorToggle');
		} catch (error) {
			generalError(error);
		} finally {
			window?.refreshOutletStatus?.();
		}
	};

	const merchantsSocketHandler = data => {
		let isAuto = false;
		let showNotif = false;
		const tempPayload = {
			count: 0,
			open: 0,
			close: 0,
			pending: [],
			success: [],
			failed: [],
			retryable: [],
			cancelled: [],
		};

		data.forEach((merchant, index) => {
			if (merchant.lastUpdateStatus) {
				tempPayload[merchant.lastUpdateStatus].push(merchant);

				if (merchant.lastUpdateStatus === 'failed') {
					/**
					 * Check failed request
					 * if failed request is auto and turning off, no need to retry
					 * else save failed request to retryable array
					 */
					if (merchant.updateMethod === 'auto') {
						if (merchant.lastUpdateTo === true) {
							tempPayload.retryable.push(merchant);
						}
					} else {
						tempPayload.retryable.push(merchant);
					}
				}
			}

			if (merchant.merchantIsOpen) tempPayload.open++;
			else tempPayload.close++;
			tempPayload.count++;

			// Check update method
			if (index === 0 && merchant.updateMethod === 'auto') isAuto = true; // Need to recheck flow to handle auto
		});

		setMerchantsStatus(tempPayload);

		// Get elapsed time since last action, if last action is within 2 minutes, show notification
		const lastUpdateTime = data[0]?.processedAt;

		// Get the current time
		const currentTime = new Date();

		// Specify the specific time
		const specificTime = new Date(lastUpdateTime);

		// Calculate the time difference in milliseconds
		const timeDifference = currentTime - specificTime;

		// Convert milliseconds to minutes
		const elapsedMinutes = Math.floor(timeDifference / 1000 / 60);

		if (tempPayload.retryable.length) {
			// Always show notification if theres a retryable request
			showNotif = true;
		} else {
			elapsedMinutes < 2 ? showNotif = true : showNotif = false;
		}

		if (showNotif && !tempPayload.pending.length) {
			notification.hide('errorToggle');
			if (isAuto) {
				// Notification handler for auto toggle
				if (tempPayload?.success?.[0]?.lastUpdateTo === true) {
					if (tempPayload.retryable.length) {
						notification.hide('errorToggle');
						notification.error({
							id: 'errorToggle',
							autoClose: false,
							title: t('Notification.Failed.title', {
								failedQty: tempPayload.retryable.length,
							}),
							message: t('Notification.Failed.message'),
							extra: <Box mt={16}>
								<Button
									size="sm"
									leftIcon={(
										<FaDisplay
											containerSize={18}
											fontSize={14}
											faIcon={faArrowRotateRight} />
									)}
									onClick={() => handleRetry(tempPayload.retryable.map(merchant => merchant.merchantId))}
								>
									{t('Notification.Failed.action')}
								</Button>
							</Box>,
						});
					} else {
						notification.success({
							title: t('Notification.AutoToggleNotification.On.title'),
							message: t('Notification.AutoToggleNotification.On.message'),
						});
					}
				} else if (tempPayload?.success?.[0]?.lastUpdateTo === false) {
					notification.error({
						title: t('Notification.AutoToggleNotification.Off.title'),
						message: t('Notification.AutoToggleNotification.Off.message'),
						extra: <Box mt={16}>
							<Button
								size="sm"
								onClick={() => navigate('/outlet-status')}>
								{t('Notification.AutoToggleNotification.Off.action')}
							</Button>
						</Box>,
					});
				}
			} else {
				if (tempPayload.success.length || tempPayload.retryable.length) {
					if (tempPayload.retryable.length) {
						notification.hide('errorToggle');
						notification.error({
							id: 'errorToggle',
							autoClose: false,
							title: t('Notification.Failed.title', {
								failedQty: tempPayload.retryable.length,
							}),
							message: t('Notification.Failed.message'),
							extra: <Box mt={16}>
								<Button
									size="sm"
									leftIcon={(
										<FaDisplay
											containerSize={18}
											fontSize={14}
											faIcon={faArrowRotateRight} />
									)}
									onClick={() => handleRetry(tempPayload.retryable.map(merchant => merchant.merchantId))}
								>
									{t('Notification.Failed.action')}
								</Button>
							</Box>,
						});

					} else if (tempPayload.success.length) {
						notification.success({
							autoClose: 3000,
							title: t('Notification.Success.title'),
							message: t('Notification.Success.message'),
						});
					}
				}
			}
		}

		if (window?.initialLogin) {
			window.initialLogin = false;
			if (tempPayload.close > 0) {
				setCloseNotifierVisible(true);
			}
		}
	};

	function compareLastTwoWords(a, b) {
		// Split the strings into words
		const wordsA = a.split(' ');
		const wordsB = b.split(' ');

		// Check if both strings have at least two words
		if (wordsA.length < 2 || wordsB.length < 2) {
			return false;
		}

		// Get the last two words from each string
		const lastTwoA = wordsA.slice(-2).join(' ');
		const lastTwoB = wordsB.slice(-2).join(' ');

		// Compare the last two words of a and b
		return lastTwoA === lastTwoB;
	}

	const turnOffReminder = ({allowDoNothing}) => {
		const turnOnReminderActive = JSON.parse(localStorage.getItem('reminderTurnOn')) || false;
		if (outletStatusReminderVisible || turnOnReminderActive) return;
		setOutletStatusReminderVisible(true);
		setOutletIsOverload(true);
		localStorage.setItem('reminderTurnOff', true);
		localStorage.setItem('reminderTurnOn', false);
		localStorage.setItem('allowDoNothing', allowDoNothing || false);
		setIsAllowDoNothing(allowDoNothing);
	};

	const turnOnReminder = () => {
		setOutletStatusReminderVisible(true);
		setOutletIsOverload(false);
		localStorage.setItem('reminderTurnOn', true);
		localStorage.setItem('reminderTurnOff', false);
	};

	const closeReminder = () => {
		setOutletStatusReminderVisible(false);
		localStorage.setItem('reminderTurnOn', false);
		localStorage.setItem('reminderTurnOff', false);
		localStorage.setItem('allowDoNothing', false);
		setIsAllowDoNothing(false);
	};

	useEffect(() => {
		if (locationId) {
			window.refreshOutletStatus = fetchMerchantDetail;
			window?.refreshOutletStatus?.();
			socket.on(`merchant_details:${locationId}`, merchantsSocketHandler);
			socket.on('turn_off_merchant', turnOffReminder);
			socket.on('turn_on_merchant', turnOnReminder);
		}

		if (JSON.parse(localStorage.getItem('reminderTurnOn'))) turnOnReminder();
		if (JSON.parse(localStorage.getItem('reminderTurnOff'))) turnOffReminder();
		setIsAllowDoNothing(JSON.parse(localStorage.getItem('allowDoNothing')) || false);

		return () => {
			socket.off(`merchant_details:${locationId}`);
			socket.off('turn_off_merchant');
			socket.off('turn_on_merchant');
		};
	}, [locationId]);

	useEffect(() => {
		if (merchantsStatus === MERCHANT_STATUS_INITIAL_STATE) return;
		const {pending, count, close} = merchantsStatus;
		if (pending.length) {
			// Check for pending process
			setIndicatorStatus(t('somePending'));
		} else {
			// Check if all merchants is open
			if (close == 0) {
				setIndicatorStatus(t('allOpen'));
			} else {
				// Check if all merchants is close
				if (count === close) {
					setIndicatorStatus(t('allClose'));
				} else {
					// Somer merchants are close
					setIndicatorStatus(t('someClose', {
						closeCount: close,
					}));
				}
			}
		}
	}, [merchantsStatus]);

	return (
		<>
			<OutletStatusReminder
				open={outletStatusReminderVisible}
				isOverload={outletIsOverload}
				close={() => closeReminder()}
				isAllowDoNothing={isAllowDoNothing}
			/>
			<ConfirmationModal
				opened={closeNotifierVisible}
				state="warning"
				title={t('CloseNotifier.title', {
					closeCount: merchantsStatus?.close,
				})}
				subtitle={t('CloseNotifier.subtitle')}
				cancelLabel={t('CloseNotifier.cancel')}
				confirmLabel={t('CloseNotifier.confirm')}
				onClose={() => setCloseNotifierVisible(false)}
				onClickCancel={() => setCloseNotifierVisible(false)}
				onClickConfirm={() => {
					setCloseNotifierVisible(false);
					navigate('/outlet-status');
				}}
			/>
			{
				!allOpen && (
					<Group
						onClick={() => navigate('/outlet-status')}
						className={cx(classes.wrapper,
							{
								[classes.allCloseState]: indicatorStatus === t('allClose'),
								[classes.someCloseState]: compareLastTwoWords(indicatorStatus, t('someClose')),
								[classes.somePendingState]: indicatorStatus === t('somePending'),
							},
						)}
						noWrap
						spacing={4}>
						<FaDisplay
							faIcon={loadingIndicator ? faLoader : faCircleSmall}
							fontSize={12}
							containerSize={14}
							faProps={{spin: loadingIndicator ? true : false}}
						/>
						<Text
							typography="caption-medium-12"
							className={cx(classes.statusText)}>
							{indicatorStatus}
						</Text>
					</Group>
				)
			}
		</>
	);
};

export default OutletStatus;